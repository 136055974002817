import React from "react";
import NavigationBar from "../NavigationBar";
import Jumbotron from "../Jumbotron";
import { Col, Container, Row } from "reactstrap";
import { Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/react-hooks";
import { useState } from "react";
import { useEffect } from "react";
import { cusTableColumns } from "./assets/columns";
import { Styles } from "./assets/Styles";
import { RETRIEVE_PAYMENT_INFORMATION } from "./assets/tableQueries";

const CustomerSatTableAnt = () => {
  const [rowData, setRowData] = useState([]);
  const[filterText , setFilterText] = useState('');
  const[filteredRowData , setFilteredRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  let [retrievePaymentInformation, { loading, data, error }] = useLazyQuery(RETRIEVE_PAYMENT_INFORMATION, {
    onCompleted(data){
      let tempRowData = [];
      data.retrievePaymentInformation.map((paymentEntry) => {
        let payment = {
          fullName: paymentEntry.fullName,
          email: paymentEntry.email,
          amount: "$" + paymentEntry.amount,
          phone: paymentEntry.phone,
          redemptionDate: paymentEntry.redemptionDate,
          cardType: paymentEntry.cardType,
          ownHome: paymentEntry.ownHome,
          orderId: paymentEntry.orderId,
          orderDate: paymentEntry.orderDate,
          invoiceOrderFundingDate: paymentEntry.invoiceOrderFundingDate,
          shipMethod: paymentEntry.shipMethod,
          upsTracking: paymentEntry.upsTracking ? paymentEntry.upsTracking.reverse() : []
        };
        paymentEntry.address.map((addressEntry) => {
          let prefix = "m";
          if (addressEntry.addressType === "install") {
            prefix = "i";
          } else {
            prefix = "m";
          }
          payment[prefix + "address1"] = addressEntry.address1;
          payment[prefix + "address2"] = addressEntry.address2;
          payment[prefix + "city"] = addressEntry.city;
          payment[prefix + "state"] = addressEntry.state;
          payment[prefix + "zip"] = addressEntry.zip;
        });
        tempRowData.push(payment);
      });
      setRowData(tempRowData);
      setFilteredRowData(tempRowData);
    },
    onError(err) {
      console.log(err);
    }
    //variables: { paymentType: "CUSTOMER SATISFACTION" },
  });


  const filterData = (value) => {
    const filteredData = rowData.filter((row) => {
      return (
        (row.fullName &&
          row.fullName.toLowerCase().includes(value.toLowerCase())) ||
        (row.email && row.email.toLowerCase().includes(value.toLowerCase())) ||
        (row.phone && row.phone.toLowerCase().includes(value.toLowerCase())) ||
        (row.redemptionDate &&
          row.redemptionDate.toLowerCase().includes(value.toLowerCase())) ||
        (row.cardType &&
          row.cardType.toLowerCase().includes(value.toLowerCase())) ||
        (row.ownHome &&
          row.ownHome.toLowerCase().includes(value.toLowerCase())) ||
        (row.orderId &&
          row.orderId.toLowerCase().includes(value.toLowerCase())) ||
        (row.orderDate &&
          row.orderDate.toLowerCase().includes(value.toLowerCase())) ||
        (row.iaddress1 &&
          row.iaddress1.toLowerCase().includes(value.toLowerCase())) ||
        (row.iaddress2 &&
          row.iaddress2.toLowerCase().includes(value.toLowerCase())) ||
        (row.icity && row.icity.toLowerCase().includes(value.toLowerCase())) ||
        (row.istate &&
          row.istate.toLowerCase().includes(value.toLowerCase())) ||
        (row.izip && row.izip.toLowerCase().includes(value.toLowerCase())) ||
        (row.maddress1 &&
          row.maddress1.toLowerCase().includes(value.toLowerCase())) ||
        (row.maddress2 &&
          row.maddress2.toLowerCase().includes(value.toLowerCase())) ||
        (row.mcity && row.mcity.toLowerCase().includes(value.toLowerCase())) ||
        (row.mstate &&
          row.mstate.toLowerCase().includes(value.toLowerCase())) ||
        (row.mzip && row.mzip.toLowerCase().includes(value.toLowerCase()))
      );
    });
    setFilteredRowData(filteredData);
  };

  const onQuickFilterText = (event) => {
    const searchFor = event.target.value || ""
    const searchOption = document.getElementById("searchOption").value;
    setSearchText(searchFor)
    if(searchFor.length > 4){
      retrievePaymentInformation({
        variables: {
          paymentType: "CUSTOMER SATISFACTION",
          filterTextOption: searchOption,
          filterText: searchFor
        }
      })
    }
  }

  return (
    <>
      <NavigationBar />
      <Jumbotron />
      <br />
      <Container>
        <Row>
          <Col md={12}>
            <Styles>
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ float: "center", marginLeft: 20 }}>
                  <label htmlFor="quickFilter">Search:&nbsp;</label>
                  <select style={{ width: "15%" }} id="searchOption">
                    <option value="fullName">Full Name</option>
                    <option value="amount">Amount</option>
                    <option value="email">Email</option>
                    <option value="phone">Phone</option>
                    <option value="address1">Address 1</option>
                    <option value="token">Tracking Number</option>
                    <option value="cusLeaseNo">Account Number</option>
                    <option value="guaranteeYr">Guarantee year</option>
                  </select>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="text"
                    id="quickFilter"
                    onChange={onQuickFilterText}
                    placeholder="Type text to search..."
                  />
                </div>
              </div>

              <Table
                loading={loading}
                dataSource={filteredRowData}
                columns={cusTableColumns}
                pagination={true}
                scroll={{ x: "max-content" }}
              />
            </Styles>
          </Col>
        </Row>
      </Container>
      <br />
    </>
  );
};

export default CustomerSatTableAnt;
  
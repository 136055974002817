import React from 'react';
import gql from 'graphql-tag';
import { useState } from 'react'
import { Form, Button, Modal, Input, Select, notification } from 'antd'
import { useMutation } from "@apollo/react-hooks";
import { Col, Row } from 'react-bootstrap'
import { RoleName, removeGraphQLErrorPrefix, roleOptions, getRoleFlagObject  } from '../../_helpers/utils';


const ADD_USER = gql`
    mutation manualInsert($input: signupInput) {
        manualInsert(input: $input) {
            id
          }
    }
`;


const AddUser = () => {
    const [form] = Form.useForm()
    const [addUserShow, setAddUserShow] = useState(false)
    const [manualInsert] = useMutation(ADD_USER, {
        onCompleted(data) {
            openNotificationWithIcon('success', 'User created successfully!')
            setAddUserShow(false)
            window.location.reload();
            
        },
        onError(error) {
            console.log(error); 
            openNotificationWithIcon('error', removeGraphQLErrorPrefix(error.message))
        },
    });


    const onFinish = (values) => {

        const apiPayload = {
            input: {
                fname: values.fname,
                lname: values.lname,
                email: values.email,
                password: "",
                ...getRoleFlagObject(values.roleName)
            }
        }

        manualInsert({
            variables: apiPayload,
        })
    }


    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, description) => {
      api[type]({
        message,
        description,
      });
    };

    return (
        <>
            <Button
                variant="primary"
                className="btn-sm bps-secondary-button-sm"
                onClick={() => setAddUserShow(true)}
            >
                Add User
            </Button>
            {contextHolder}
            <Modal
                visible={addUserShow}
                onCancel={() => setAddUserShow(false)}
                className="w-75"
                maskStyle={{
                    backgroundColor: 'rgb(0, 0, 0, 0.3)',
                }}
                footer={null}
                destroyOnClose={true}
                title="Add User"
            >
                <Form form={form} onFinish={onFinish}>
                    <div className="shadow-sm border rounded p-4">
                        <Row>
                            <Col md={6}>
                                <Form.Item
                                    label="First Name"
                                    name="fname"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: 'Please enter first name',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={6}>
                                <Form.Item
                                    label="Last Name"
                                    name="lname"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: 'Please enter last name',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                          required: true,
                                          whitespace: true,
                                          message: 'Please enter an email address',
                                        },
                                        {
                                          type: 'email',
                                          message: 'Invalid email format',
                                        },
                                      ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={6}>
                                <Form.Item
                                    label="Role"
                                    name="roleName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a role',
                                        },
                                    ]}
                                >
                                    <Select options={roleOptions} />
                                </Form.Item>
                            </Col>
                        </Row>


                    </div>
                    <br />
                    <center>
                        <Button variant="primary" htmlType="submit">
                            Save
                        </Button>
                    </center>
                </Form>
            </Modal>
        </>
    )
}

export default AddUser;
//used in sorter functionality
//function is checking for the null values before locale comparison
//If a null value is encountered, it is treated as a lower value so that it appears before non-null values when sorted.
export const compareValues = (valueA, valueB) => {
  if (
    (valueA === null && valueB === null) ||
    (valueA === undefined && valueB === undefined)
  )
    return 0;
  if (valueA === null || valueA === undefined) return -1;
  if (valueB === null || valueB === undefined) return 1;
  return valueA.localeCompare(valueB);
};

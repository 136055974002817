import React, { useState, useEffect } from "react";
//import { Jumbotron as Jumbo, Container,  Row,Col } from 'react-bootstrap';
import "antd/dist/antd.css";
import { Modal, Button, Form, Input, Spin, Table, Upload } from "antd";
import Icon from "@ant-design/icons";
import { BsFileText } from "react-icons/bs";
import axios from "axios";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import "./NoteIcon.css";
const { TextArea } = Input;

const createNote = (paymentId, note, imageUrl) => `
  mutation {
    createNote(paymentId:"${paymentId}", note:"${note}", imageUrl:"${imageUrl}") {
        note
        userName
        noteCreationDate
        delete
        noteId
        imageUrl
    }
  }
`;
const uploadNoteQuery = gql`
  mutation UploadNote($file: Upload, $key: String) {
    uploadNote(file: $file, key: $key)
  }
`;

const deleteNote = (noteId, paymentId) => `
  mutation {
    deleteNote(noteId:"${noteId}", paymentId: "${paymentId}") {
        note
        userName
        noteCreationDate
        delete
        noteId
        imageUrl
    }
  }
`;

const query = gql`
  query getNotes($paymentId: String) {
    getNotes(paymentId: $paymentId) {
      note
      userName
      noteCreationDate
      delete
      noteId
      imageUrl
    }
  }
`;

const axiosGraphQL = axios.create({
  baseURL: process.env.REACT_APP_NODE_URL,
});

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const NoteIcon = (props) => {
  //console.log(props.value);
  const [form] = Form.useForm();
  const [paymentId, setPaymentId] = useState("");
  const [doesNoteExist, setDoesNoteExist] = useState(false);
  const [note, setNote] = useState("");
  const [noteData, setNoteData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  let { loading, data, error } = useQuery(query, {
    variables: { paymentId: paymentId },
  });
  const [imageUrl, setImageUrl] = useState("");

  const [uploadNote] = useMutation(uploadNoteQuery);

  const formatDate = () => {
    let d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    const hr = d.getHours();
    const min = d.getMinutes();
    const sec = d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day, hr, min, sec].join("");
  };

  const propsUpload = {
    customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    }) {
      let cleanedFileName = file.name.replace(/[^a-zA-Z0-9\.]/g, "");
      let finalFileName = formatDate() + "_" + cleanedFileName;
      try {
        uploadNote({ variables: { file, key: finalFileName } }).then(
          res => {
              onSuccess(res.data.uploadNote, file);
              setImageUrl(res.data.uploadNote)
          },
          err => {
            console.log('error caught: ',err); 
          }
          );
      } catch(err) {
        console.log('in uploader err: ',err);
      }
    },
  };

  useEffect(() => {
    setTimeout(() => {
      if (data && !loading ) {
        let tempRowData = [];

        data.getNotes.map((noteEntry) => {
          let note = {
            note: noteEntry["note"],
            userName: noteEntry["userName"],
            noteCreationDate: noteEntry["noteCreationDate"],
            delete: noteEntry["delete"],
            noteId: noteEntry["noteId"],
            imageUrl: noteEntry["imageUrl"],
          };
          tempRowData.push(note);
        });
        setNoteData(tempRowData);
        setDoesNoteExist(props.data.doesNoteExist);
        setSpinner(false);
      }
    }, 1000);
  }, [data, loading]);

  const buttonClicked = () => {
    setPaymentId(props.data.paymentId);
    showModal();
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const createNoteAction = (e) => {
    const token = localStorage.getItem("currentUser");
    setSpinner(true);
    axiosGraphQL
      .post(
        "",
        {
          query: createNote(paymentId, note, imageUrl),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((result) => {
        if (result.data.data.createNote) {
          form.resetFields();
          let tempRowData = [];
          result.data.data.createNote.map((noteEntry) => {
            let note = {
              note: noteEntry["note"],
              userName: noteEntry["userName"],
              noteCreationDate: noteEntry["noteCreationDate"],
              delete: noteEntry["delete"],
              noteId: noteEntry["noteId"],
              imageUrl: noteEntry["imageUrl"],
            };
            tempRowData.push(note);
          });
          setNoteData(tempRowData);
          setSpinner(false);
        }
      });
  };

  const captureNote = (e) => {
    let noteVal = e.target.value;
    if (e.target.value.indexOf("\n") !== -1) {
      noteVal = '""' + e.target.value + '""';
    }
    setNote(noteVal);
  };

  const deleteNoteAction = (noteId) => {
    const token = localStorage.getItem("currentUser");
    setSpinner(true);
    axiosGraphQL
      .post(
        "",
        {
          query: deleteNote(noteId, paymentId),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((result) => {
        if (result.data.data.deleteNote) {
          let tempRowData = [];
          result.data.data.deleteNote.map((noteEntry) => {
            let note = {
              note: noteEntry["note"],
              userName: noteEntry["userName"],
              noteCreationDate: noteEntry["noteCreationDate"],
              delete: noteEntry["delete"],
              noteId: noteEntry["noteId"],
              imageUrl: noteEntry["imageUrl"],
            };
            tempRowData.push(note);
          });
          setNoteData(tempRowData);
          setSpinner(false);
        }
      });
  };

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const noteColumns = [
    {
      title: "Note Creator",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Note Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (text, record) => (
        <span>
          {record.imageUrl ? (
            <a href={record.imageUrl} target="_blank">
              Download Image
            </a>
          ) : null}
        </span>
      ),
    },
    {
      title: "Creation Date",
      dataIndex: "noteCreationDate",
      key: "noteCreationDate",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <span>
          {record.delete ? (
            <a href="#" onClick={(e) => deleteNoteAction(record.noteId)}>
              Delete
            </a>
          ) : null}
        </span>
      ),
    },
  ];

  return (
    <span>
      <BsFileText
        style={{ color: `${doesNoteExist ? 'green': 'blue'}`, fontSize: "15px", marginLeft: "5px" }}
        onClick={() => buttonClicked()}
      />
      {props.value}
      <Modal
        title="Notes"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={800}
        footer={[
          <Spin spinning={spinner} tip="Loading...">
            <Table
              columns={noteColumns}
              dataSource={noteData}
              scroll={{ y: 200 }}
            />
          </Spin>,
        ]}
      >
        <Form form={form} layout="vertical" className="note-form">
          <Form.Item label="Note" name="note" {...formItemLayout}>
            <TextArea rows={4} cols={50} onChange={captureNote} />
          </Form.Item>
          <Upload {...propsUpload}>
            <Button>
              <Icon type="upload" /> Click or drag file to this area to upload
            </Button>
          </Upload>
          <Form.Item {...tailLayout}>
            <Button htmlType="button" onClick={(e) => createNoteAction(e)}>
              Create Note
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </span>
  );
};

export default NoteIcon;

import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import {
    Form,
    InputNumber,
    Checkbox, Col, Row, DatePicker, Button
  } from 'antd';
import 'antd/dist/antd.css';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CheckBoxGrid from './CheckBoxGrid';

const Styles = styled.div`
    .ant-checkbox-group  {
        width: 100%;
    }
    .ant-btn-primary {
        background-color: orange;
    }
`;
const mutation = gql`
  mutation checkExport {
    checkExport {
        fullName
        amount
        attentionTo
        checkDate
        email
        phone
        memo
        address {
            address1
            address2
            city
            state
            zip
        }
    }
  }
`;

function formatColumn(worksheet, col, fmt) {
    const range = XLSX.utils.decode_range(worksheet['!ref'])
    // note: range.s.r + 1 skips the header row
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col })
        if (worksheet[ref] && worksheet[ref].t === 'n') {
            worksheet[ref].z = fmt
        }
    }
}

const CheckReport = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const formItemLayout = {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 24,
        },
      };
    /*const colLabels = {'attentionTo': 'Company', 'amount': 'Opening Balance', 'email':'Email', 'phone': 'Phone', 'address1' : 'Street','fullName': 'Name', 
    'city': 'City', 'state':'State', 'zip':'Zip', 'fax': 'Fax', 'mobile': 'Mobile', 'country': 'Country', 'checkDate': 'Date',
    'taxId': 'Tax ID', 'memo': 'Memo'};
    const headerOrder = {'fullName': 'Name', 'attentionTo': 'Company', 'email':'Email', 'phone': 'Phone', 'mobile': 'Mobile', 'fax': 'Fax', 'memo': 'Memo'
                        ,'address1' : 'Street', 'city': 'City', 'state':'State', 'zip':'Zip', 'country': 'Country', 'amount': 'Opening Balance', 'checkDate': 'Date', 'taxId': 'Tax ID'};*/

    /*const colLabels = {'attentionTo': 'Company', 'amount': 'Opening Balance', 'address1' : 'Address 1', 'address2' : 'Address 2','fullName': 'Name', 
    'city': 'City', 'state':'State', 'zip':'Zip', 'checkDate': 'Date','memo': 'Memo'};*/
    const colLabels = {'fullName': 'Name', 'attentionTo': 'Company',
    'address1' : 'Address 1', 'address2' : 'Address 2','city': 'City', 'state':'State', 'zip':'Zip'
    , 'amount': 'Amount', 'checkDate': 'Date','memo': 'Memo'};
    const headerOrder = {'fullName': 'Name', 'attentionTo': 'Company',
                        'address1' : 'Address 1', 'address2' : 'Address 2','city': 'City', 'state':'State', 'zip':'Zip'
                        , 'amount': 'Amount', 'checkDate': 'Date','memo': 'Memo'};
    const blankFields = ['mobile', 'fax', 'taxId', 'country'];
    const colFormats = ['@', '@','@','@','@','@','@','00000', '0.00', 'mm/dd/YYYY', '@'];
    let [pullData, {loading, data}] = useMutation(mutation);
    const fileName = "CheckReport";
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    
    const onFinish = values => {
        //console.log(columns);
        //console.log(reportDates);
        setSpinnerDisplay(true);
        pullData().then(
            res => {
                setSpinnerDisplay(false);
                console.log(res);
                let tempRowData = [];
                
                res.data.checkExport.map( paymentEntry => {
                    let payment = {};
                    const keys = Object.keys(paymentEntry);
                    const addressFields = ['address1', 'city', 'state', 'zip'];
                    //console.log(reportCols);
                    keys.map(colKey => {
                        if (colKey !== 'address' && colKey !== '__typename' && colKey !== 'phone' && colKey !== 'email') {
                            payment[colLabels[colKey]] = paymentEntry[colKey];
                        } else if (colKey === 'address') {
                            addressFields.map(akey => {
                                const address2 = (paymentEntry.address['address2']) ? ' '+paymentEntry.address['address2'] : '';
                                paymentEntry.address[akey] = (akey === 'address1') ? paymentEntry.address['address1']+ address2 : paymentEntry.address[akey];
                                payment[colLabels[akey]] = paymentEntry.address[akey];
                            });
                        }
                    })
                    /*blankFields.map(blankCol => {
                        payment[colLabels[blankCol]] = '';
                    });*/
                    //console.log(payment);
                    tempRowData.push(payment);
                });
                const columnOrder = [];
                const workbook = XLSX.utils.book_new()
                for (let key in headerOrder) {
                    //console.log(key,':',headerOrder[key]);
                    columnOrder.push(headerOrder[key]);

                }
                
                const worksheet = XLSX.utils.json_to_sheet(tempRowData, {header:columnOrder, dateNF: 'mm/dd/yyyy'});
                for (let i = 0; i < colFormats.length; i++) {
                    formatColumn(worksheet, (i+1), colFormats[i]);
                }
                XLSX.utils.book_append_sheet(workbook, worksheet, 'CheckReport')
                //const ws = XLSX.utils.json_to_sheet(tempRowData, {header:columnOrder});
                //const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const data2 = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data2, fileName + fileExtension);
            },
            err => {console.log(err); }
            );
    };
    
    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text='Loading your report data...'
                >
            <NavigationBar />
            <Jumbotron />
                <br />
            <Styles>
                
                
            <main>
                <div className="container">
	                <h2>Check Report</h2>	          
                    <p>Reports will be produced and downloaded as Excel</p>
                    <Form
                        name="report"
                        {...formItemLayout}
                        onFinish={onFinish}
                        >
                    <div className="card">
                        <div className="card-body">
                            <Row>
                                <Col span={6} offset={10}>
                                <Button type="primary" htmlType="submit" shape="round" size="large">
                                    Export Report
                                </Button>
                                </Col> 
                            </Row>

                            
                        </div>
                    </div>
                    </Form>
                </div>
                <br />
                
            </main>
            </Styles>
            </LoadingOverlay>
            
        </Fragment>
    );
}

export default CheckReport;
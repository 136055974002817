import gql from 'graphql-tag';

const paymentsUploaderSubscription = gql`
    subscription paymentsFileUpdateStatus {
        paymentsFileUpdateStatus {
            type
            message
        }
    }
`;

export default paymentsUploaderSubscription;
import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import {
    Form,
    InputNumber,
    Checkbox, Col, Row, DatePicker, Button
  } from 'antd';
import 'antd/dist/antd.css';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CheckBoxGrid from './CheckBoxGrid';

const Styles = styled.div`
    .ant-checkbox-group  {
        width: 100%;
    }
    .ant-btn-primary {
        background-color: orange;
    }
`;
const mutation = gql`
  mutation physicalExport {
    physicalExport {
        fname
        lname
        amount
        email
        phonearea
        phonenumber
        trackingNo
        address {
            address1
            address2
            city
            state
            zip
        }
    }
  }
`;
const PhysicalCardReport = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const formItemLayout = {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 24,
        },
      };
    
    const colLabels = {
        'trackingNo': 'PID Number', 'prefix': 'Prefix', 'fname': 'First Name', 'mname': 'Middle Name', 'lname': 'Last Name', 'suffix': 'Suffix', 'ename': 'Emboss Name',
        'fourth': 'Fourth Line', 'address1': 'Address Line1', 'address2': 'Address Line2', 'address3': 'Address Line3', 'city': 'Home City', 'state': 'Home State', 'zip': 'Home Zip', 
        'phonearea': 'Home Phone Area Code', 'phonenumber': 'Home Phone Number', 'ext': 'Home Phone Extension', 'email': 'Email Address', 'amount':'Funding Amount',
        'desc': 'Transaction Description', 'carrier': 'Card Carrier Message', 'status': 'Program Participation Status', 'data1': 'Indicative Data 1',
        'data2' : 'Indicative Data 2', 'data3': 'Indicative Data 3', 'data4': 'Indicative Data 4', 'data5':'Indicative Data 5', 'contact': 'Bulk Ship Contact',
        'baddress1': 'Bulk Ship Address Line 1', 'baddress2': 'Bulk Ship Address Line 2', 'attention': 'Shipping Attention', 'bcity': 'Bulk Ship City',
        'bstate': 'Bulk Ship State or Province', 'bzip': 'Bulk Ship Zip or Postal Code', 'bcountry': 'Bulk Ship Extended Zip or Country'
    };
    /*const colLabels = {'fullName': 'Company', 'amount': 'Opening Balance', 'email':'Email', 'phone': 'Phone', 'address1' : 'Street','attentionTo': 'Name', 
    'city': 'City', 'state':'State', 'zip':'Zip', 'fax': 'Fax', 'mobile': 'Mobile', 'country': 'Country', 'checkDate': 'Date',
    'taxId': 'Tax ID', 'memo': 'Website'};*/
    /*const headerOrder = {'attentionTo': 'Name', 'fullName': 'Company', 'email':'Email', 'phone': 'Phone', 'mobile': 'Mobile', 'fax': 'Fax', 'memo': 'Website'
                        ,'address1' : 'Street', 'city': 'City', 'state':'State', 'zip':'Zip', 'country': 'Country', 'amount': 'Opening Balance', 'checkDate': 'Date', 'taxId': 'Tax ID'};*/
    const blankFields = ['prefix', 'mname', 'suffix', 'ename', 'fourth', 'address3', 'ext', 'desc', 'carrier', 'status', 'data1', 'data2', 'data3', 'data4', 'data5',
                        'contact', 'baddress1', 'baddress2', 'attention', 'bcity', 'bstate', 'bzip', 'bcountry'];
    
    let [pullData, {loading, data}] = useMutation(mutation);
    const fileName = "PhysicalReport";
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    
    const onFinish = values => {
        //console.log(columns);
        //console.log(reportDates);
        setSpinnerDisplay(true);
        pullData().then(
            res => {
                setSpinnerDisplay(false);
                console.log(res);
                let tempRowData = [];
                
                res.data.physicalExport.map( paymentEntry => {
                    let payment = {};
                    const keys = Object.keys(paymentEntry);
                    const addressFields = ['address1', 'city', 'state', 'zip'];
                    //console.log(reportCols);
                    keys.map(colKey => {
                        if (colKey !== 'address' && colKey !== '__typename') {
                            payment[colLabels[colKey]] = paymentEntry[colKey];
                        } else if (colKey === 'address') {
                            addressFields.map(akey => {
                                //paymentEntry.address[akey] = (akey === 'address1') ? paymentEntry.address['address1']+' '+paymentEntry.address['address2'] : paymentEntry.address[akey];
                                payment[colLabels[akey]] = (paymentEntry.address[akey]) ? paymentEntry.address[akey] : '';
                            });
                        }
                    })
                    blankFields.map(blankCol => {
                        payment[colLabels[blankCol]] = '';
                    });
                    //console.log(payment);
                    tempRowData.push(payment);
                });
                const columnOrder = [];
        
                for (let key in colLabels) {
                    //console.log(key,':',headerOrder[key]);
                    columnOrder.push(colLabels[key]);
                }
                const ws = XLSX.utils.json_to_sheet(tempRowData, {header:columnOrder});
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data2 = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data2, fileName + fileExtension);
            },
            err => {console.log(err); }
            );
    };
    
    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text='Loading your report data...'
                >
            <NavigationBar />
            <Jumbotron />
                <br />
            <Styles>
                
                
            <main>
                <div className="container">
	                <h2>Physical Card Report</h2>	          
                    <p>Reports will be produced and downloaded as Excel</p>
                    <Form
                        name="report"
                        {...formItemLayout}
                        onFinish={onFinish}
                        >
                    <div className="card">
                        <div className="card-body">
                            <Row>
                                <Col span={6} offset={10}>
                                <Button type="primary" htmlType="submit" shape="round" size="large">
                                    Export Report
                                </Button>
                                </Col> 
                            </Row>

                            
                        </div>
                    </div>
                    </Form>
                </div>
                <br />
                
            </main>
            </Styles>
            </LoadingOverlay>
            
        </Fragment>
    );
}

export default PhysicalCardReport;
import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { Table, Tag, Space } from 'antd';
import gql from "graphql-tag";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import 'antd/dist/antd.css';
import {
    Form, Input,Button, Row, Col
  } from 'antd';
import { Alert } from 'reactstrap';
import { formatAmount, formatDate } from '../../_helpers/utils';

const { Column, ColumnGroup } = Table;
const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 32,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  const query = gql`
  query listCheckOrders {
    listCheckOrders {
        checkId
        fileName
        created
        updatedAt
        status
        reportKey
        processed
        reportTotal
        upsFileName
    }
  }
`;

const mutation = gql`
    mutation markCheckOrderAsProcessed($checkId: String){
        markCheckOrderAsProcessed(checkId: $checkId) {
            checkId
            fileName
            created
            updatedAt
            status
            reportKey
            processed
            reportTotal
            upsFileName
        }
    }
`;
const CheckOrder = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(true);
    const [spinnerDisplay2, setSpinnerDisplay2] = useState(false);
    const [loaderText, setLoaderText] = useState('Loading your reported orders...');
    let {loading, data, error} = useQuery(query);
    const [tabledata, setTableData] = useState([]);
    const [showSuccessMessage, setshowSuccessMessage] = useState(false);
    const [showErrorMessage, setshowErrorMessage] = useState(false);
    const client = useApolloClient();
    
    useEffect(() => {
        if (data && !loading && spinnerDisplay) {
            console.log(data);
            const tableData = [];
            data.listCheckOrders.map(entry => {
                if (entry.status === 'success') {
                    const orderEntry = {
                        checkId: entry.checkId,
                        fileName: entry.fileName,
                        created: entry.created,
                        updatedAt: entry.updatedAt,
                        reportKey: entry.reportKey,
                        processed: (entry.processed) ? 'Processed' : 'Pending',
                        reportPending: entry.processed,
                        reportTotal: formatAmount(entry.reportTotal),
                        upsFileName: entry.upsFileName
                    };
                    console.log()
                    tableData.push(orderEntry);
                }
                
            })
            setTableData(tableData);
            setSpinnerDisplay(false);
        }
    }, [spinnerDisplay, data, loading]);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    const [orderFunding] = useMutation(mutation);
    

    const orderToFund = (orderId, fileName, processed) => {
        if (!processed && localStorage.getItem('superUser') === 'true') {
            setSpinnerDisplay2(true);
            setLoaderText('Funding your report order...');
            orderFunding({
                variables: { checkId: orderId }
                }).then(
                res => {
                    console.log('res: ',res);
                    if (res) {
                        setshowSuccessMessage(true);
                        setSpinnerDisplay2(false);
                        const tableData = [];
                        res.data.markCheckOrderAsProcessed.map(entry => {
                            if (entry.status === 'success') {
                                const orderEntry = {
                                    checkId: entry.checkId,
                                    fileName: entry.fileName,
                                    created: entry.created,
                                    updatedAt: entry.updatedAt,
                                    reportKey: entry.reportKey,
                                    processed: (entry.processed) ? 'Processed' : 'Pending',
                                    reportPending: entry.processed,
                                    reportTotal: formatAmount(entry.reportTotal),
                                    upsFileName: entry.upsFileName
                                };
                                tableData.push(orderEntry);
                            }
                        });
                        setTableData(tableData);
                        window.open(fileName, '_blank');
                    }
                },
                err => {
                    console.log(err); 
                    setshowErrorMessage(true);
                }
                );
        } else {
            window.open(fileName, '_blank');
        }
    }

    return (
        <>
        <LoadingOverlay
            active={spinnerDisplay || spinnerDisplay2}
            spinner
            text={loaderText}
            >
        <NavigationBar />
        <Jumbotron />
            <br /> 
                    <Row>
                        <Col span={12} offset={5}>
                        <Alert color="success" style={{ display: ((showSuccessMessage) ? 'block' : 'none') }}>
                              The check order was successfully processed.  
                        </Alert>
                        <Alert color="danger" style={{ display: ((showErrorMessage) ? 'block' : 'none') }}>
                            There was an error processing the check order.
                        </Alert>
                        </Col>
                    </Row>
               
                <Row>
                    <Col span={16} offset={4}> 
                        <Table dataSource={tabledata}>
                            <Column title="Check ID" dataIndex="checkId" key="checkId" />
                            <Column title="Date Created" dataIndex="created" key="created" />
                            <Column title="Report Total" dataIndex="reportTotal" key="reportTotal" />
                            <Column title="Check Report Processed" dataIndex="processed" key="processed" />
                            <Column 
                                title="Download Report" 
                                dataIndex="fileName" 
                                key="fileName" 
                                render={(text, record) => record.fileName ? (
                                    <span>
                                      {localStorage.getItem('superUser') === 'true' || record.reportPending ? <a onClick={() => orderToFund(record.reportKey, record.fileName, record.reportPending)}>Download Report</a> : "" }
                                    </span>
                                 ) : ""}
                            />
                            <Column 
                                title="UPS Report" 
                                dataIndex="upsFileName" 
                                key="upsFileName" 
                                render={(text, record) => record.upsFileName ? (
                                    <span>
                                      {localStorage.getItem('superUser') === 'true' || record.reportPending ? <a onClick={() => orderToFund(record.reportKey, record.upsFileName, record.reportPending)}>UPS Report</a> : "" }
                                    </span>
                                 ): ""}
                            />
                            <Column title="Funded Date" dataIndex="updatedAt" key="updatedAt" render={(_, record) => record.reportPending ? record.updatedAt : ""} />
                        </Table>
                    </Col>
                </Row>
        
        </LoadingOverlay>
    </>
    );
}

export default CheckOrder;
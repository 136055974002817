import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { Table, Tag, Space, Tooltip } from 'antd';
import gql from "graphql-tag";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import 'antd/dist/antd.css';
import {
    Form, Input,Button, Row, Col, Modal
  } from 'antd';
import { Alert } from 'reactstrap';
import EditForm from './EditForm';
import { InfoCircleOutlined } from '@ant-design/icons'

const { Column, ColumnGroup } = Table;
const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 32,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  const query = gql`
  query searchPayments($searchString: String, $editSearch: Boolean){
    searchPayments(searchString: $searchString, editSearch: $editSearch) {
        paymentType
        amount
        cusLeaseNo
        guaranteeYr
        fullName
        email
        phone
        paymentId
        status
        formString
        cardType
        fundingStatus
        attentionTo
        redemptionDate
        upsTracking
        shipMethod
        voidPayment
        isSSNEntered
        editPaymentSSNCheck
    }
  }
`;

const mutation = gql`
    mutation EditPayment($paymentId: String, $email: String, $mailingAddress1: String, $mailingAddress2: String, $mailingCity: String, $mailingState: String, $mailingZip: String, $fullName: String, $phone: String, $cardType: String, $amount: Float, $voidPayment: Boolean, $addrChangeReason: String, $checkAction: String, $reissueDate: String, $attentionTo: String, $upsTracking: [String], $shipMethod: String){
        editPayment(input: {paymentId: $paymentId, email: $email, mailingAddress1: $mailingAddress1, mailingAddress2: $mailingAddress2, 
            mailingCity: $mailingCity, mailingState: $mailingState, mailingZip: $mailingZip, fullName: $fullName, phone: $phone, cardType: $cardType, amount: $amount, voidPayment: $voidPayment, addrChangeReason: $addrChangeReason, checkAction: $checkAction, reissueDate: $reissueDate, attentionTo: $attentionTo, upsTracking: $upsTracking, shipMethod: $shipMethod}) 
    }
`;
const EditPayment = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const [spinnerDisplay2, setSpinnerDisplay2] = useState(false);
    const [loaderText, setLoaderText] = useState('Searching your data...');
    const [tabledata, setTableData] = useState([]);
    const [showSuccessMessage, setshowSuccessMessage] = useState(false);
    const [showErrorMessage, setshowErrorMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalFieldValues, setModalFieldValues] = useState({});
    const [modalPaymentId, setModalPaymentId] = useState(0);
    const [searchString, setSearchString] = useState(false);
    const [voidPayment, setVoidPayment] = useState(false);

    const [searchQuery, { loading, data }] = useLazyQuery(query, {
        onCompleted(data) {
        // console.log('data', data)
        const tableData = [];
        data.searchPayments.map(entry => {
            //console.log(entry);
            if (entry.status === 'success') {
                const searchEntry = {
                    fullName: entry.fullName,
                    paymentType: entry.paymentType,
                    phone: entry.phone,
                    amount: entry.amount,
                    email: entry.email,
                    key: entry.paymentId,
                    paymentId: entry.paymentId,
                    formString: JSON.parse(entry.formString),
                    cardType: entry.cardType,
                    fundingStatus: entry.fundingStatus,
                    attentionTo: entry.attentionTo,
                    redemptionDate: entry.redemptionDate,
                    guaranteeYr: entry.guaranteeYr,
                    shipMethod: entry.shipMethod,
                    upsTracking: entry.upsTracking ? entry.upsTracking.reverse() : [],
                    voidPayment: entry.voidPayment,
                    isSSNEntered: entry.isSSNEntered,
                    editPaymentSSNCheck: entry.editPaymentSSNCheck
                };
                //console.log()
                tableData.push(searchEntry);
            }
            
        })
        setTableData(tableData);
        setSpinnerDisplay(false);
        setSpinnerDisplay2(false);
    },
  });

    const client = useApolloClient();
    const onFinish = values => {
        if (searchString !== values.searchString.trim()) {
            console.log('Success:', values);
            setSpinnerDisplay(true);
            setshowSuccessMessage(false);
            setshowErrorMessage(false);
            setSearchString(values.searchString.trim());
            searchQuery({
                variables: {
                    searchString: values.searchString.trim(),
                    editSearch: true
            }});
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    const [editPayment] = useMutation(mutation);

    const checkVoidPayment = (value) => {
        //console.log('checkbox:'+value.target.value);
        //console.log(value.target.checked);
        setVoidPayment(value.target.checked);
    }

    const editPaymentEntry = (values) => {
        setModalVisible(false);
        //console.log(values);
        const cardType = (values.cardType) ? values.cardType : '';
        setSpinnerDisplay2(true);
        setLoaderText('Updating the payment entry...');
        const addrChangeReason = (values.addrChangeReason === 'Other') ? values.addrChangeReason+'-'+values.otherReason : values.addrChangeReason;
        editPayment({
            variables: { 
                paymentId: values.paymentId,
                email:  values.email,
                mailingAddress1: values.mailingAddress1,
                mailingAddress2: values.mailingAddress2,
                mailingCity: values.mailingCity,
                mailingState: values.mailingState,
                mailingZip: values.mailingZip,
                fullName: values.fullName,
                phone: values.phone,
                cardType: cardType,
                amount: parseFloat(values.amount),
                voidPayment: voidPayment,
                addrChangeReason,
                checkAction: values.checkAction,
                reissueDate: values.reissueDate,
                attentionTo: values.attentionTo,
                shipMethod: values.shipMethod,
                upsTracking: values.upsTracking ? values.upsTracking : []
            }
            }).then(
            res => {
                //console.log('res: ',res);
                if (res) {
                    setshowSuccessMessage(true);
                    
                    setshowErrorMessage(false);
                    setLoaderText('Searching again after update...');
                    client.query({
                        query,
                        variables: {
                            searchString,
                            editSearch: true
                        },
                        fetchPolicy: "network-only"
                      }).then(data => {
                          //console.log('debug:',data);
                          const tableData = [];
                          data.data.searchPayments.map(entry => {
                            const searchEntry = {
                                fullName: entry.fullName,
                                paymentType: entry.paymentType,
                                phone: entry.phone,
                                amount: entry.amount,
                                email: entry.email,
                                key: entry.paymentId,
                                paymentId: entry.paymentId,
                                formString: JSON.parse(entry.formString),
                                cardType: entry.cardType,
                                fundingStatus: entry.fundingStatus,
                                attentionTo: entry.attentionTo,
                                redemptionDate: entry.redemptionDate,
                                guaranteeYr: entry.guaranteeYr,
                                shipMethod: entry.shipMethod,
                                upsTracking: entry.upsTracking ? entry.upsTracking.reverse() : [],
                                voidPayment: entry.voidPayment,
                                isSSNEntered: entry.isSSNEntered,
                                editPaymentSSNCheck: entry.editPaymentSSNCheck
                            };
                            //console.log()
                            tableData.push(searchEntry);
                        })
                        setTableData(tableData);
                        setSpinnerDisplay(false);
                        setSpinnerDisplay2(false);
                      });
                    
                }
            },
            err => {
                console.log(err); 
                setshowErrorMessage(true);
            }
            );
    }

    const onCancel = () => {
        setShowModal(false);
        setModalVisible(false);
    }

    const prepareModal = (record) => {
        setModalVisible(true);
        const formValues = {formString: record.formString, fullName: record.fullName, 
            cardType: record.cardType, phone: record.phone, amount: record.amount, fundingStatus: record.fundingStatus,
            paymentType: record.paymentType, attentionTo: record.attentionTo, 
            upsTracking: record.upsTracking ? record.upsTracking.reverse() : [], 
            shipMethod: record.shipMethod, voidPayment: record.voidPayment,
            isSSNEntered: record.isSSNEntered,
            editPaymentSSNCheck: record.editPaymentSSNCheck
        };
        //console.log(formValues);
        //setModalFieldValues(record.formString);
        setModalFieldValues(formValues);
        setModalPaymentId(record.paymentId);
        setVoidPayment(record.voidPayment);
    }

    return (
        <>
        <LoadingOverlay
            active={spinnerDisplay || spinnerDisplay2}
            spinner
            text={loaderText}
            >
        <NavigationBar />
        <Jumbotron />
            <br /> 
                    <Row>
                        <Col span={12} offset={5}>
                        <Alert color="success" style={{ display: ((showSuccessMessage) ? 'block' : 'none') }}>
                              The payment has been sucessfully edited.  
                        </Alert>
                        <Alert color="danger" style={{ display: ((showErrorMessage) ? 'block' : 'none') }}>
                            There was an error editing the payment.
                        </Alert>
                        </Col>
                    </Row>
                    <Form
                    {...layout}
                    name="Search"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    >
                    <Row>
                    <Col span={12} offset={5}>
                        <Form.Item
                            name="searchString"
                            rules={[
                            {
                                required: true,
                                message: 'Please enter Search Text',
                            },
                            ]}
                        >
                            <Input placeholder="Search by name, email or phone"/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                            Search
                            </Button>
                        </Form.Item>
                    </Col>
                    </Row>
                    </Form>
               
                <Row>
                    <Col span={16} offset={4}> 
                        <Table dataSource={tabledata} scroll={{x:1300}}>
                            <Column title="Name" dataIndex="fullName" key="fullName" />
                            <Column title="Attention To" dataIndex="attentionTo" key="attentionTo" />
                            <Column title="Email" dataIndex="email" key="email" />
                            <Column title="Phone" dataIndex="phone" key="phone" />
                            <Column title="Amount" dataIndex="amount" key="Amount" />
                            <Column title="Reward Type" dataIndex="cardType" key="cardType" />
                            <Column title="UPS Tracking"  width={180} render = {(_, record) =>{
                                return (
                                    <>
                                    {record.upsTracking && (
                                            <>
                                                <Tooltip title={
                                                    <div className="d-flex flex-column gap-2">
                                                        {record.upsTracking.map((item, index) => (
                                                            <div key={index}>{item}{index !== record.upsTracking.length - 1}</div>
                                                        ))}
                                                    </div>
                                                } color='#E2620E'>
                                                    <div>
                                                        {record.upsTracking[0]} {record.upsTracking.length > 0 && (
                                                            <InfoCircleOutlined className="cursor-auto"/>
                                                        )}
                                                    </div>
                                                </Tooltip>
                                            </>
                                    )}
                                    </>
                                )
                            }} />
                            <Column title="Redeemed Date" dataIndex="redemptionDate" key="redemptionDate" />
                            <Column title="Payment Type" dataIndex="paymentType" key="paymentType" />
                            <Column title="Guarantee Year" dataIndex="guaranteeYr" key="guaranteeYr" />
                            <Column title="Funding Status" dataIndex="fundingStatus" key="fundingStatus" />
                            <Column title="Ship Method" dataIndex="shipMethod" key="shipMethod" />
                            <Column
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                <a onClick={() => prepareModal(record)}>Edit Payment</a>
                                {
                                    modalVisible ? 
                                    <EditForm
                                    visible={true}
                                    onSaveResend={editPaymentEntry}
                                    onCancel={onCancel}
                                    fieldValues={modalFieldValues}
                                    paymentId={modalPaymentId}
                                    displayAll={true}
                                    title="Edit"
                                    checkVoidPayment={checkVoidPayment}
                                    displayCSR={false}
                                    displayEmail={false}
                                    voidPayment={voidPayment}
                                    /> : null
                                }
                                
                                </Space>
                            )}
                            />
                        </Table>
                    </Col>
                </Row>
        
        </LoadingOverlay>
    </>
    );
}

export default EditPayment;
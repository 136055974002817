import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useSubscription } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import {
    Form,
    InputNumber,
    Checkbox, Col, Row, DatePicker, Button
  } from 'antd';
import 'antd/dist/antd.css';
//import Download from './Download';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CheckBoxGrid from './CheckBoxGrid';

const Styles = styled.div`
    .ant-checkbox-group  {
        width: 100%;
    }
    .ant-btn-primary {
        background-color: orange;
    }
`;
const query = gql`
  query retrievePaymentInformation($paymentType: String, $startDate: String, $endDate: String, $adminFlag:Boolean){
    retrievePaymentInformation(paymentType: $paymentType, startDate: $startDate, endDate: $endDate, adminFlag: $adminFlag) {
        paymentType
        amount
        cusLeaseNo
        guaranteeYr
        fullName
        email
        phone
        address {
            address1
            address2
            city
            state
            zip
            addressType
        }
        redemptionDate
        cardType
        ownHome
        orderDate
        shipDate
        shipMethod
        invoiceOrderFundingDate
    }
  }
`;
const VppReport = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    
    const formItemLayout = {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 24,
        },
      };
    const colLabels = {'fullName': 'Customer First and Last Name', 'email': 'Email', 'phone': 'Phone', 'paymentType': 'Payment Type', 
        'cardType': 'Card Type', 'redemptionDate': 'Redemption Date', 'amount': 'Amount', 
        'guaranteeYr':'Guarantee Year', 'cusLeaseNo': 'Customer Lease/Number', 'orderDate': 'Order Date', 
        'invoiceOrderFundingDate':'Email Sent Date', 'address1': 'Install Address1',
        'address2': 'Install Address2', 'city': 'Install City', 'state':'Install State', 'zip':'Install Zip', 'maddress1': 'Mailing Address1','maddress2': 'Mailing Address2', 
        'mcity': 'Mailing City', 'mstate': 'Mailing State', 'mzip': 'Mailing Zip', shipDate: 'Shipped Date', shipMethod: 'Ship Method'
    };
    const headerOrder = {'fullName': 'Customer First and Last Name', 'email': 'Email', 'phone': 'Phone', 'paymentType': 'Payment Type', 
        'cardType': 'Card Type', 'redemptionDate': 'Redemption Date', 'amount': 'Amount', 
        'guaranteeYr':'Guarantee Year', 'cusLeaseNo': 'Customer Lease/Number', 'orderDate': 'Order Date', 
        'invoiceOrderFundingDate':'Email Sent Date', 'address1': 'Install Address1',
        'address2': 'Install Address2', 'city': 'Install City', 'state':'Install State', 'zip':'Install Zip', 'maddress1': 'Mailing Address1','maddress2': 'Mailing Address2', 
        'mcity': 'Mailing City', 'mstate': 'Mailing State', 'mzip': 'Mailing Zip', shipDate: 'Shipped Date', shipMethod: 'Ship Method'
    };
    
    const [reportDates, setReportDates] = useState({'sdate': '', "edate": ''});
    let [pullData, {loading, data }] = useLazyQuery(query, {
        fetchPolicy: 'network-only'
      });
    
    const fileName = "VPP-Payments-Report";
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [reportCols, setReportCols] = useState({});
    
    useEffect(() => {
        
        setTimeout(()=>{
            if (data && !loading && spinnerDisplay) {
            
                //console.log(data);
                let tempRowData = [];
                
                data.retrievePaymentInformation.map(paymentEntry => {
                    let payment = {};
                    const keys = Object.keys(paymentEntry);
                    const addressFields = ['address1', 'address2', 'city', 'state', 'zip'];
                    //console.log(reportCols);
                    keys.map(colKey => {
                        if (colKey !== 'address') {
                            if (reportCols[colKey]) {
                                payment[colLabels[colKey]] = paymentEntry[colKey];
                            }
                        } else if (colKey === 'address') {
                            paymentEntry.address.map(addressEntry => {
                                const prefix = (addressEntry.addressType === 'mailing') ? 'm':'';
                                addressFields.map(akey => {
                                    const addressKey = prefix+akey;
                                    if (reportCols[addressKey]) {
                                        payment[colLabels[addressKey]] = addressEntry[akey];
                                    }
                                });
                            })
                        }
                    })
                    tempRowData.push(payment);
                });
        
                const columnOrder = [];
                
                for (let key in headerOrder) {
                    //console.log(key,':',headerOrder[key]);
                    if (reportCols[key]) {
                        columnOrder.push(headerOrder[key]);
                    }
                }
                
                if (spinnerDisplay && tempRowData.length > 0) {
                    //console.log('tempRowData: ',tempRowData);
                    const ws = XLSX.utils.json_to_sheet(tempRowData, {header:columnOrder});
                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    let data2 = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data2,  fileName+ "[" +reportDates['sdate'] + "_" + reportDates['edate'] + "]" + fileExtension);
                    data2 = undefined;
                }
                tempRowData = [];
                setSpinnerDisplay(false);
            }
        }, 1000);
        
    }, [data, loading]);
    

    const onFinish = async (values) => {
        //console.log(columns);
        //console.log(reportDates);
        setSpinnerDisplay(true);
        
        let result = await pullData({
            variables: {
                paymentType: 'VPP',
                startDate: reportDates['sdate'],
                endDate: reportDates['edate'],
                adminFlag: false
            }
        });
        
        
    };

    const startDateCapture = date => {
        console.log(date.format('YYYY-MM-DD'));
        let tempDate = reportDates;
        tempDate['sdate'] = date.format('YYYY-MM-DD');
        setReportDates(tempDate);
    }

    const endDateCapture = date => {
        console.log(date.format('YYYY-MM-DD'));
        let tempDate = reportDates;
        tempDate['edate'] = date.format('YYYY-MM-DD');
        setReportDates(tempDate);
    }

    
    const colNames = {'redemptionDate':'', 'amount':'', 'guaranteeYr':'','cusLeaseNo':'','fullName':'','email':'','phone':'',
                    'cardType':'','paymentType':'', 'shipDate': '', 'shipMethod': '',
                    'address1':'', 'address2':'', 'city':'', 'state':'', 'zip':'',
                    'maddress1':'', 'maddress2':'', 'mcity':'', 'mstate':'', 'mzip':'', 'orderDate': '',
                    'invoiceOrderFundingDate': ''};

    const onCheckBoxChange = (colSelected) => {
        console.log(colSelected);
        setReportCols(colSelected);
    }

    const checkBoxList = [
        [
            {'id': 'redemptionDate', 'label':'Redemption Date'},
            {'id': 'guaranteeYr', 'label':'Guarantee Year'},
            {'id': 'cusLeaseNo', 'label':'Customer Lease/Number'},
            {'id': 'invoiceOrderFundingDate', 'label':'Email Sent Date'},
            {'id': 'shipMethod', 'label':'Ship Method'},
            {'id': 'shipDate', 'label':'Shipped Date'}

        ],
        [
            {'id': 'fullName', 'label':'Name'},
            {'id': 'email', 'label':'Email'},
            {'id': 'phone', 'label':'Phone'},
            {'id': 'amount', 'label':'Amount'},
            {'id': 'cardType', 'label':'Card Type'},
            {'id': 'paymentType', 'label':'Payment Type'},
            
        ],
        [
            {'id': 'address1', 'label':'Install Address1'},
            {'id': 'address2', 'label':'Install Address2'},
            {'id': 'city', 'label':'Install City'},
            {'id': 'state', 'label':'Install State'},
            {'id': 'zip', 'label':'Install Zip'},
            {'id': 'orderDate', 'label':'Order Date'}
        ],
        [
            {'id': 'maddress1', 'label':'Mailing Address1'},
            {'id': 'maddress2', 'label':'Mailing Address2'},
            {'id': 'mcity', 'label':'Mailing City'},
            {'id': 'mstate', 'label':'Mailing State'},
            {'id': 'mzip', 'label':'Mailing Zip'}
        ]
    ];

    

    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text='Loading your report data...'
                >
            <NavigationBar />
            <Jumbotron />
                <br />
            <Styles>
                
                
            <main>
                <div className="container">
	                <h2>VPP Payments Report</h2>	          
                    <p>Reports will be produced and downloaded as Excel</p>
                    <Form
                        name="report"
                        {...formItemLayout}
                        onFinish={onFinish}
                        >
                    <div className="card">
                        <div className="card-body">
                            
                            <CheckBoxGrid
                                checkBoxList={checkBoxList}
                                onCheckBoxChange = {onCheckBoxChange}
                                colNames = {colNames}
                            />
                            
                            <div className="form-row bg-light p-2 mt-4">
                                <div className="col-md">
                                    <div className="form-group">
                                    <Form.Item label="Start Date" value="sdate">
                                        <DatePicker onChange={startDateCapture} />
                                    </Form.Item>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group">
                                    <Form.Item label="End Date" value="edate">
                                        <DatePicker onChange={endDateCapture} />
                                    </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <br />
                            <Row>
                                <Col span={6} offset={10}>
                                <Button type="primary" htmlType="submit" shape="round" size="large">
                                    Export Report
                                </Button>
                                </Col> 
                            </Row>

                            
                        </div>
                    </div>
                    </Form>
                </div>
                <br />
                
            </main>
            </Styles>
            </LoadingOverlay>
            
        </Fragment>
    );
}

export default VppReport;
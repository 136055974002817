import gql from "graphql-tag";

export const RETRIEVE_PAYMENT_INFORMATION = gql`
  query retrievePaymentInformation($filterText: String $filterTextOption: String $paymentType: String) {
    retrievePaymentInformation(filterText: $filterText filterTextOption: $filterTextOption paymentType: $paymentType) {
      paymentType
      amount
      cusLeaseNo
      guaranteeYr
      fullName
      email
      phone
      address {
        address1
        address2
        city
        state
        zip
        addressType
      }
      redemptionDate
      cardType
      ownHome
      orderId
      orderDate
      invoiceOrderFundingDate
      upsTracking
      shipMethod
    }
  }
`;

export const GET_ALL_TYPE_TABLE_DATA = gql`
  query getAllTypeTableDataChunks($allTypeInput: allTypeInput) {
    getAllTypeTableDataChunks(input: $allTypeInput) {
      allTypeTableData {
        paymentId
        paymentType
        amount
        cusLeaseNo
        guaranteeYr
        fullName
        email
        phone
        address {
          address1
          address2
          city
          state
          zip
          addressType
        }
        redemptionDate
        cardType
        ownHome
        orderId
        orderDate
        shipDate
        shipMethod
        token
        cusLeaseNo
        addrChangeReason
        autoRedeemed
        checkAction
        reissueDate
        doesNoteExist
        attentionTo
      }
      pageInfo {
        totalDocs
      }
    }
  }
`;

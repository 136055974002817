import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { RoleName } from '../../_helpers/utils';

const Styles = styled.div`
  .navbar {
        background-color: #fff;
    }

    a, .navbar-brand, .navbar-nav .nav-link {
        color: #6c757d;

        &:hover {
            color: hsla(0,0%,0%,1.00);
	        border-bottom: #F18B21 2px solid;
        }
        &:active {
            color: hsla(0,0%,0%,1.00);
	        border-bottom: #F18B21 2px solid;
        }
    }

    .nav-link.tab-title {
            color: #858585;
            background-color: #EEEEEE;
        }

        .nav-link.tab-title:hover {
            color: #E2620E;
        }

        .nav-link.tab-title.active {
            color: #E2620E;
        }
`;

const NavigationBar = props => {
    const bpsAdminMenu = () => {
        if (localStorage.getItem('roleName') === RoleName.ROOT_ADMIN) {
            return (
                <NavDropdown title="BPS Admin" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.0" href='/users'>Users</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.1" href='/invoice-order'>Invoice Order</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.1" href='/resendemail'>Resend Email</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/checkOrder'>Check Report</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/unredeemedreport'>Unredeemed Report</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/fundReport'>Fund Report</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/editPayment'>Edit Payment</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.1" href='/csreditPayment'>Redeem Payment</NavDropdown.Item>
                </NavDropdown>
            );
        } else if (localStorage.getItem('roleName') === RoleName.MANAGEMENT_LEVEL || localStorage.getItem('roleName') === RoleName.SUPPORT_LEVEL) {
            return (
                <NavDropdown title="BPS Admin" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1" href='/editPayment'>Edit Payment</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.1" href='/resendemail'>Resend Email</NavDropdown.Item> 
                </NavDropdown>
            );
        } else if (localStorage.getItem('roleName') === RoleName.ACCOUNTING_LEVEL) {
            return (
                <NavDropdown title="BPS Admin" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1" href='/fundReport'>Fund Report</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/checkOrder'>Check Report</NavDropdown.Item>  
                </NavDropdown>
            )
        }
    }

    const uploaderMenu = () => {
        if (localStorage.getItem('roleName') === RoleName.ROOT_ADMIN || localStorage.getItem('roleName') === RoleName.CLIENT_ADMIN) {
            return (
                <Nav.Item>
                        <Nav.Link href="/uploader">Uploader</Nav.Link>
                </Nav.Item>
            );
        } 
        /*else if (localStorage.getItem('bpsadminFlag') === 'true' && (localStorage.getItem('email').toLowerCase() === 'bpstesting01@gmail.com')) {
            return (
                <NavDropdown title="BPS Admin" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1" href='/resendemail'>Resend Email</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/editPayment'>Edit Payment</NavDropdown.Item> 
                </NavDropdown>
            );
        } */
        
    }

    const reportMenu = () => {
        if (localStorage.getItem('roleName') === RoleName.ROOT_ADMIN || localStorage.getItem('roleName') === RoleName.CLIENT_ADMIN) {
            return (
                <NavDropdown title="Reports" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1" href='/pegureport'>Performance Energy Reports</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2" href='/cussatreport'>Customer Satisfaction Reports</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2" href='/addresschangereport'>Change Address Reports</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2" href='/alltypereport'>All Type Reports</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2" href='/changeInfoReport'>Change Info Report</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2" href='/ricPaymentReport'>RIC Payment Report</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.7" href='/lien-report'>Lien Filings Report</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.7" href='/processed-report'>Payment Processed Report</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.7" href='/vpp-report'>VPP Payments Report</NavDropdown.Item>
                </NavDropdown>
            );
        } else if (localStorage.getItem('roleName') === RoleName.ACCOUNTING_LEVEL) {
            return (
                <NavDropdown title="Reports" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.2" href='/alltypereport'>All Type Reports</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.7" href='/processed-report'>Payment Processed Report</NavDropdown.Item>
                </NavDropdown>
            )
        } 
    }

    const backLogMenu = () => {
        if (localStorage.getItem('roleName') === RoleName.ROOT_ADMIN || localStorage.getItem('roleName') === RoleName.CLIENT_ADMIN) {
            return (
                <NavDropdown title="Backlog" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1" href='/backloguploader'>Backlog Uploader</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2" href='/backlogtable'>Backlog Data</NavDropdown.Item>
                </NavDropdown>
            );
        }
    }

    return (
        <Styles>
            <Navbar expand="lg">
            <Navbar.Brand href="/"><img src="images/SunPower.png" width="180px" alt="logo"/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="justify-content-center mx-auto">
                    <Nav.Item>
                        <Nav.Link href="/">Home</Nav.Link>
                    </Nav.Item>
                    {uploaderMenu()}
                    <NavDropdown title="Payments Processed" id="nav-dropdown">
                        <NavDropdown.Item eventKey="4.1" href='/pegutable' hidden={localStorage.getItem('roleName') === RoleName.ACCOUNTING_LEVEL}>Performance Energy Payments</NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2" href='/cussattable' hidden={localStorage.getItem('roleName') === RoleName.ACCOUNTING_LEVEL}>Customer Satisfaction Payments</NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2" href='/alltypetable'>All Type Payments</NavDropdown.Item>
                    </NavDropdown>
                    {reportMenu()}
                    {backLogMenu()}
                    {bpsAdminMenu()}
                </Nav>
            </Navbar.Collapse>
            </Navbar>
        </Styles >
    );
};

export default NavigationBar;

import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useSubscription } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import {
    Form,
    InputNumber,
    Checkbox, Col, Row, DatePicker, Button
  } from 'antd';
import 'antd/dist/antd.css';
//import Download from './Download';
import { saveAs } from 'file-saver';
import Excel from 'exceljs';
import CheckBoxGrid from './CheckBoxGrid';

const Styles = styled.div`
    .ant-checkbox-group  {
        width: 100%;
    }
    .ant-btn-primary {
        background-color: orange;
    }
`;
const query = gql`
  query InfoChangeReport($startDate: String, $endDate: String){
        changeInfoReport(startDate:$startDate, endDate: $endDate) {
            fullName
            email
            phone
            adminFname
            adminLname
            dateChange
            editedFields {
                old
                new
            }
        }
  }
`;
const ChangeInfoReport = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const formItemLayout = {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 24,
        },
      };
    const colLabels = [
        { header: 'Name', key: 'fullName' },
        { header: 'Email', key: 'email' },
        { header: 'Phone', key: 'phone' },
        { header: 'Editor First Name', key: 'adminFname' },
        { header: 'Editor Last Name', key: 'adminLname' },
        { header: 'Change Date', key: 'dateChange' },
        { header: 'Changed Data', key: 'editedFields' }
      ];
    //const colLabels = {'fullName' : 'Name', 'email': 'Email', 'phone': 'Phone', 'editedFields': 'Changed Data'}
    /*const headerOrder = {'fullName': 'Name', 'email': 'Email', 'phone': 'Phone', 'paymentType': 'Payment Type', 'cardType': 'Reward Type', 'redemptionDate': 'Date Submission Entered', 'amount': 'Amount', 
        'token': 'Tracking Number', 'guaranteeYr':'Guarantee Years', 'cusLeaseNo': 'Lease / Customer Number', 'orderId': 'Order Id', 'orderDate': 'Order Date', 'addrChangeReason': 'Address Change Reason', 'address1': 'Address1',
        'address2': 'Address2', 'city': 'City', 'state':'State', 'zip':'Zip', 'maddress1': 'New Address','maddress2': 'New Address2', 'mcity': 'New City', 'mstate': 'New State', 'mzip': 'New Zip'};
    */
    const headerOrder = {'fullName' : 'Name', 'email': 'Email', 'phone': 'Phone', 'editedFields': 'Changed Data'}
    const [checkBoxValues, setCheckBoxValues] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
    const [columns, setColumns] = useState([]);
    const [reportDates, setReportDates] = useState({'sdate': '', "edate": ''})
    let [pullData, {loading, data}] = useLazyQuery(query);
    const fileName = "ChangeInfoReport";
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [reportCols, setReportCols] = useState({});
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Edited Data");
    worksheet.columns = colLabels;
    worksheet.getRow(1).font = { bold: true };
    worksheet.columns.forEach(column => {
        const padding  = (column.header === 'Changed Data') ? 100 : (column.header === 'Email') ? 30 : 10;
        column.width = column.header.length + padding;
        column.alignment = { horizontal: 'center' };
    });

    useEffect(() => {
        
        setTimeout(async ()=>{
            if (data && !loading && spinnerDisplay) {
                
                //console.log(data);
                let tempRowData = [];
                
                data.changeInfoReport.map(paymentEntry => {
                    let payment = {};
                    const keys = Object.keys(paymentEntry);
                    //console.log(reportCols);
                    let reportFlag = false;
                    keys.map(colKey => {
                        if (colKey !== 'editedFields') {
                            payment[colKey] = paymentEntry[colKey];
                        } else if (colKey === 'editedFields') {
                            let dataStr = '';
                            paymentEntry.editedFields.map(editedDataEntry => {
                                dataStr = dataStr + editedDataEntry.old+' : '+editedDataEntry.new+"\n";
                                reportFlag = true;
                            })
                            payment[colKey] = dataStr;
                        }
                    })
                    if (reportFlag) {
                        tempRowData.push(payment);
                    }
                });
                
                if (spinnerDisplay) {
                    tempRowData.forEach(singleData => {
                        worksheet.addRow(singleData);
                    });
                    worksheet.eachRow({ includeEmpty: false }, row => {
                        // store each cell to currentCell
                        const currentCell = row._cells;
                
                        // loop through currentCell to apply border only for the non-empty cell of excel
                        currentCell.forEach(singleCell => {
                          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                          const cellAddress = singleCell._address;
                
                          // apply border
                          worksheet.getCell(cellAddress).alignment = { wrapText: true };
                        });
                      });
                
                      // write the content using writeBuffer
                      const buf = await workbook.xlsx.writeBuffer();
                
                      // download the processed file
                      saveAs(new Blob([buf]), `${fileName}.xlsx`);
                    
                }
                tempRowData = [];
                setSpinnerDisplay(false);
            }
        }, 1000);
        
    }, [data, loading]);

    const onFinish = values => {
        //console.log(columns);
        //console.log(reportDates);
        setSpinnerDisplay(true);
        pullData({
            variables: {
                startDate: reportDates['sdate'],
                endDate: reportDates['edate']
            }
        });
        
    };

    const startDateCapture = date => {
        console.log(date.format('YYYY-MM-DD'));
        let tempDate = reportDates;
        tempDate['sdate'] = date.format('YYYY-MM-DD');
        setReportDates(tempDate);
    }

    const endDateCapture = date => {
        console.log(date.format('YYYY-MM-DD'));
        let tempDate = reportDates;
        tempDate['edate'] = date.format('YYYY-MM-DD');
        setReportDates(tempDate);
    }

    
    const colNames = {'redemptionDate':'', 'amount':'', 'token':'','fullName':'','email':'','phone':'',
                    'address1':'', 'address2':'', 'city':'', 'state':'', 'zip':'',
                    'maddress1':'', 'maddress2':'', 'mcity':'', 'mstate':'', 'mzip':'', 'orderId': '', 'orderDate': '', 'addrChangeReason': ''};

    const onCheckBoxChange = (colSelected) => {
        console.log(colSelected);
        setReportCols(colSelected);
    }

    const checkBoxList = [
        [
            {'id': 'redemptionDate', 'label':'Date Submission Entered'},
            {'id': 'amount', 'label':'Amount'},
            {'id': 'token', 'label':'Tracking Number'},
            {'id': 'orderId', 'label':'Order Id'}
        ],
        [
            {'id': 'fullName', 'label':'Name'},
            {'id': 'email', 'label':'Email'},
            {'id': 'phone', 'label':'Phone'},
            {'id': 'orderDate', 'label':'Order Date'},
            {'id': 'addrChangeReason', 'label':'Address Change Reason'}
        ],
        [
            {'id': 'address1', 'label':'Address1'},
            {'id': 'address2', 'label':'Address2'},
            {'id': 'city', 'label':'City'},
            {'id': 'state', 'label':'State'},
            {'id': 'zip', 'label':'Zip'},
        ],
        [
            {'id': 'maddress1', 'label':'New Address1'},
            {'id': 'maddress2', 'label':'New Address2'},
            {'id': 'mcity', 'label':'New City'},
            {'id': 'mstate', 'label':'New State'},
            {'id': 'mzip', 'label':'New Zip'},
        ]
    ];

    

    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text='Loading your report data...'
                >
            <NavigationBar />
            <Jumbotron />
                <br />
            <Styles>
                
                
            <main>
                <div className="container">
	                <h2>Change Information Reporting</h2>	          
                    <p>Reports will be produced and downloaded as Excel</p>
                    <Form
                        name="report"
                        {...formItemLayout}
                        onFinish={onFinish}
                        >
                    <div className="card">
                        <div className="card-body">
                            
                            
                            
                            <div className="form-row bg-light p-2 mt-4">
                                <div className="col-md">
                                    <div className="form-group">
                                    <Form.Item label="Start Date" value="sdate">
                                        <DatePicker onChange={startDateCapture} />
                                    </Form.Item>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group">
                                    <Form.Item label="End Date" value="edate">
                                        <DatePicker onChange={endDateCapture} />
                                    </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <br />
                            <Row>
                                <Col span={6} offset={10}>
                                <Button type="primary" htmlType="submit" shape="round" size="large">
                                    Export Report
                                </Button>
                                </Col> 
                            </Row>

                            
                        </div>
                    </div>
                    </Form>
                </div>
                <br />
                
            </main>
            </Styles>
            </LoadingOverlay>
            
        </Fragment>
    );
}

export default ChangeInfoReport;
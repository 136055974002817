import React, { useState } from 'react';
import { Select, Modal, Form, Input, Radio, Checkbox, DatePicker, Button, Row, Col } from 'antd';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { RoleName } from '../../_helpers/utils';

const { Option } = Select;
const EditForm = ({ visible, onSaveResend, onCancel, fieldValues, paymentId, displayAll, title, checkVoidPayment, displayCSR, displayEmail, editEmail, voidPayment }) => {
  const [form] = Form.useForm();
  const [showOther, setShowOther] = useState(false);
  const [validateOther, setValidateOther] = useState(false);
  const [validateReason, setValidateReason] = useState(false);
  const shipDateCondn = (fieldValues.formString.checkAction && fieldValues.formString.checkAction === 'Reissued') ? true : false;
  const [showShipDate, setShowShipDate] = useState(shipDateCondn);
  const [validateShipDate, setValidateShipDate] = useState(false);
  const reissueDate = (fieldValues.formString.reissueDate) ? moment(fieldValues.formString.reissueDate) : '';
  const cardTypeValdn = (fieldValues.cardType && fieldValues.cardType === 'check') ? true : false;
  const editPaymentSSNCheck = (fieldValues.paymentType.toLowerCase() === 'vpp payment'  && !fieldValues.editPaymentSSNCheck)
  const [showCheckAction, setShowCheckAction] = useState(cardTypeValdn);
  
  console.log(fieldValues);
  const handleAddressChange = (value) => {
    if (value === 'Other') {
      setShowOther(true);
      setValidateOther(true);
    } else {
      setShowOther(false);
      setValidateOther(false);
    }
  }

  const handleCheckAction = (value) => {
    if (value === 'Reissued') {
      setShowShipDate(true);
      setValidateShipDate(true);
    } else {
      setShowShipDate(false);
      setValidateShipDate(false);
    }
  }

  const handleCardTypeChange = (e) => {
    const cardTypeValdn = (e.target.value === 'check') ? true : false;
    setShowCheckAction(cardTypeValdn);
  }
  
  const isSuperUser = localStorage.getItem('roleName') === RoleName.ROOT_ADMIN;
  return (
    <Modal
      visible={visible}
      title={title}
      okText={title}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onSaveResend(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          email: fieldValues.formString.email,
          mailingAddress1: fieldValues.formString.mailingAddress,
          mailingAddress2: fieldValues.formString.mailingAddress2,
          mailingCity: fieldValues.formString.mailingCity,
          mailingState: fieldValues.formString.mailingState,
          mailingZip: fieldValues.formString.mailingZip,
          paymentId,
          fullName: fieldValues.fullName,
          amount: fieldValues.amount,
          phone: fieldValues.phone,
          cardType: fieldValues.cardType,
          checkAction: fieldValues.formString.checkAction,
          reissueDate,
          attentionTo: fieldValues.attentionTo,
          shipMethod: fieldValues.shipMethod,
          upsTracking: fieldValues.upsTracking
        }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: (localStorage.getItem('superUser') === 'true' ? false : ((!displayCSR || displayEmail) && (fieldValues.paymentType && fieldValues.paymentType.toLowerCase() !== 'vendor payment' && fieldValues.paymentType.toLowerCase() !== 'lien filing'))),
              type: 'email',
            },
          ]}
          style={!displayCSR || displayEmail  ? {display: "block"} : {display: 'none'}} >
          <Input placeholder="Email" disabled={editEmail || !isSuperUser} />
        </Form.Item>
        <Form.Item
          name="mailingAddress1"
          rules={[
            {
              required: !displayCSR && fieldValues.paymentType.toLowerCase() !== 'vendor payment' && fieldValues.paymentType.toLowerCase() !== 'lien filing',
            },
          ]}
          style={!displayCSR  ? {display: "block"} : {display: 'none'}} >
          <Input placeholder="Mailing Address1" onChange={(e)=>setValidateReason(true)} disabled={!isSuperUser}/>
        </Form.Item>
        <Form.Item
          name="mailingAddress2"
          style={!displayCSR  ? {display: "block"} : {display: 'none'}} >
          <Input placeholder="Mailing Address2" disabled={!isSuperUser} onChange={(e)=>setValidateReason(true)} />
        </Form.Item>
        <Form.Item
          name="mailingCity"
          rules={[
            {
              required: !displayCSR && fieldValues.paymentType.toLowerCase() !== 'vendor payment' && fieldValues.paymentType.toLowerCase() !== 'lien filing',
            },
          ]}
          style={!displayCSR  ? {display: "block"} : {display: 'none'}} >
          <Input placeholder="Mailing City" disabled={!isSuperUser} onChange={(e)=>setValidateReason(true)} />
        </Form.Item>
        <Form.Item
          name="mailingState"
          rules={[
            {
              required: !displayCSR && fieldValues.paymentType.toLowerCase() !== 'vendor payment' && fieldValues.paymentType.toLowerCase() !== 'lien filing',
            },
          ]}
          style={!displayCSR  ? {display: "block"} : {display: 'none'}} >
          <Input placeholder="Mailing State" disabled={!isSuperUser} onChange={(e)=>setValidateReason(true)} />
        </Form.Item>
        <Form.Item
          name="mailingZip"
          rules={[
            {
              required: !displayCSR && fieldValues.paymentType.toLowerCase() !== 'vendor payment' && fieldValues.paymentType.toLowerCase() !== 'lien filing',
            },
          ]}
          style={!displayCSR  ? {display: "block"} : {display: 'none'}} >
          <Input placeholder="Mailing Zip" disabled={!isSuperUser} onChange={(e)=>setValidateReason(true)} />
        </Form.Item>
        <Form.Item
            name="addrChangeReason"
            rules={[
                {
                required: validateReason && !displayCSR,
                message: 'Please select why are you changing your address!',
                },
            ]}
            style={!displayCSR  ? {display: "block"} : {display: 'none'}}
            >
            <Select disabled={!isSuperUser} placeholder="Why are you changing your address?" value={fieldValues.addrChangeReason} onChange={(e) => handleAddressChange(e)}>
                <Option value='Temporarily Away'>Temporarily Away</Option>
                <Option value='Change PO Box Address'>Change PO Box Address</Option>
                <Option value='We have moved'>We have moved</Option>
                <Option value='Other'>Other</Option>	
            </Select>
        </Form.Item>
        {showOther ?
          
          <Form.Item name="otherReason" 
              rules={[
                  {
                  required: validateOther && !displayCSR,
                  message: 'Please select other reason for Address Change!',
                  },
              ]}
              style={!displayCSR  ? {display: "block"} : {display: 'none'}} >
            <Input placeholder="Reason for Address Change"  disabled={!isSuperUser}/>
          </Form.Item>
        : null }
        <Form.Item
          name="fullName"
          rules={[
            {
              required: displayAll ? true: false,
            },
          ]}
          style={ (displayAll || !displayCSR) ? { display: "block" } : { display: "none" }}
        >
          <Input placeholder="Full Name" disabled={!isSuperUser}/>
        </Form.Item>
        <Form.Item
          name="attentionTo"
          style={ (displayAll || !displayCSR) ? { display: "block" } : { display: "none" }}
        >
          <Input placeholder="Attention To" disabled={!isSuperUser}/>
        </Form.Item>
        {localStorage.getItem('superUser') === 'true' && cardTypeValdn && fieldValues.fundingStatus !== 'pending' && (
                  <Form.List
                  name="upsTracking"
                >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                        <Form.Item
                        required={false}
                        key={field.key}
                      >
                        <Row>
                        <Col>
                          <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please remove empty items",
                            },
                          ]}

                        >
                          <Input placeholder="UPS tracking number"/>
                        </Form.Item>
                        </Col>
                        <Col>
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                          </Col>
                      </Row>
                      </Form.Item>

                    ))}
                      <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '60%' }}
                        icon={<PlusOutlined />}
                      >
                        {fields.length > 0 ? 'Add Another' : 'Add UPS Tracking'}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
        ) }
        <Form.Item
          name="amount"
          rules={[
            {
              required: displayAll ? true: false,
            },
          ]}
          style={ (displayAll || !displayCSR) ? { display: "block" } : { display: "none" }}
        >
          <Input placeholder="Amount" disabled={!isSuperUser}/>
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: (localStorage.getItem('superUser') === 'true' ? false : displayAll && fieldValues.paymentType.toLowerCase() !== 'vendor payment' && fieldValues.paymentType.toLowerCase() !== 'lien filing' ? true: false),
            },
          ]}
          style={ (displayAll || !displayCSR) ? { display: "block" } : { display: "none" }}
        >
          <Input placeholder="Phone"/>
        </Form.Item>
        {!displayEmail && (voidPayment || editPaymentSSNCheck) && (
            <span style={{color:'red'}}>
              {voidPayment ? 'This payment has been voided and the reward type cannot be selected.' : 'This payment cannot be manually selected since a SSN needs to be collected. The email must be resent to the customer for them to enter their SSN.'}
            </span>
          )}
        <Form.Item name="cardType" label="Reward Type"  style={ ((displayAll || displayCSR) && !displayEmail) ? { display: "block" } : { display: "none" }} 
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value === "virtualvisa" && getFieldValue('amount') > 1000) {
                  return Promise.reject('You cannot select Virtual card since amount > 1000');
      
                } else if (value === "visareward" && getFieldValue('amount') > 2500) {
                  return Promise.reject('You cannot select Physical card since amount > 2500');
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Radio.Group value={fieldValues.cardType} onChange={(e) => handleCardTypeChange(e)} disabled={(voidPayment || editPaymentSSNCheck)}>
            <Radio value="visareward">Physical</Radio>
            <Radio value="virtualvisa">Virtual</Radio>
            <Radio value="check">Check</Radio>
          </Radio.Group>
        </Form.Item>
        
        <Form.Item name="voidPayment" style={ (displayAll || !displayCSR) ? { display: "block" } : { display: "none" }} >
          <Checkbox onChange={checkVoidPayment} checked={voidPayment} disabled={!isSuperUser}>Void Payment</Checkbox>
        </Form.Item>
        <Form.Item
          name="paymentId"
          hidden={true}
        >
          <Input  />
        </Form.Item>
        { fieldValues.fundingStatus === 'processing' ? 
          <Form.Item
              label="Processed Payments Only"
              name="checkAction"
              rules={[
                  {
                  required: false,
                  },
              ]}
              style={!displayCSR  ? {display: "block"} : {display: 'none'}}
              >
              <Select placeholder="Checks Action" value={fieldValues.checkAction} onChange={(e) => handleCheckAction(e)}>
                  <Option value='Voided'>Voided</Option>
                  <Option value='Reissued'>Reissued</Option>	
              </Select>
          </Form.Item>
        : null}

        {showShipDate ?
          <Form.Item
              label="Shipping Method"
              name="shipMethod"
              rules={[
                  {
                  required: false,
                  },
              ]}
              style={!displayCSR  ? {display: "block"} : {display: 'none'}}
              >
              <Select placeholder="Select Shipping Method" value={fieldValues.shipMethod}>
                  <Option value='USPS'>Standard USPS</Option>
                  <Option value='UPS'>UPS</Option>
              </Select>
          </Form.Item>
        : null}

        {showShipDate  ?
          
          <Form.Item name="reissueDate" 
              rules={[
                  {
                  required: validateShipDate && !displayCSR,
                  message: 'Please select reissue date for Reissued check!',
                  },
              ]}
              style={!displayCSR  ? {display: "block"} : {display: 'none'}} >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
        : null }
      </Form>
    </Modal>
  );
};

export default EditForm;
import React from 'react';
import { Jumbotron as Jumbo, Container,  Row,Col } from 'react-bootstrap';
import styled from 'styled-components';
import { authenticationService } from '../../_services';
import { history } from '../../_helpers';

const Styles = styled.div`
   .divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
   }
   .BlueBtnOutline{
	font-weight: 500;
	border: 1px solid #0076BE;
	color: #0076BE;
    }

    .BlueBtnOutline:hover{
        background-color:#0076BE;
        color: #FFFFFF;
    }

    .BlueBtnOutline:focus{
        background-color:#39607A;
        color: #FFFFFF;
    }

    .BlueBtnOutline:active{
        background-color:#39607A;
        color: #FFFFFF;
    }
  .jumbo {
    background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
    min-height: 20em;
  }

  
`;

const Jumbotron = () => {
    const handleLogout = () => {
      authenticationService.logout();
      history.push('/login');
      window.location='/login';
    }

    return (
      <Styles>
      <Container className="divider-light"></Container>
      <Container className="jumbo">
          <Row>
              <Col md={4} className="bg-white m-4 shadow-sm p-4">
              <h5>{localStorage.getItem('fname')} {localStorage.getItem('lname')}</h5>
              <h6>{localStorage.getItem('title') !== "null" ? localStorage.getItem('title'): ""}</h6>
              <p>{localStorage.getItem('roleName') !== "null" ? localStorage.getItem('roleName'): ""}</p>
              
              <hr />
              
              <h5>Contact Details <a href="#userProfile" className="btn btn-link text-secondary d-md-none" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="userProfile"><i className="material-icons align-bottom">arrow_drop_down</i></a></h5>
                  
              
              <div className="collapse d-md-block" id="userProfile" aria-expanded="true">
              <h6 className="mb-0">Phone:</h6>
              <p>{localStorage.getItem('phone') !== "null" ? localStorage.getItem('phone') : ""}</p>
              <h6 className="mb-0">Email:</h6>
              <p>{localStorage.getItem('email')}</p>
                  
                  <hr />
                  
              <a className="btn btn-sm btn-block BlueBtnOutline" href="#" onClick={(e) => handleLogout()}>Logout</a>
              </div>
              </Col>
          
          </Row>	
      </Container>
    </Styles>
    );
}

export default Jumbotron;
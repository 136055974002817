import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { history } from './_helpers';
import { authenticationService } from './_services';
import { PrivateRoute } from './_components';
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
//import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from 'apollo-link';

import Uploader from './Components/MyAccount/Uploader';
import LoginPage from './Components/Login/Login';
import CustomerSatReport from './Components/MyAccount/CustomerSatReport';
import PeguReport from './Components/MyAccount/PeguReport';
import AddressChangeReport from './Components/MyAccount/AddressChangeReport';
import AllStatusReport from './Components/MyAccount/AllStatusReport';
import LienFilingReport from './Components/MyAccount/LienFilingReport';
import PaymentProcessedReport from './Components/MyAccount/PaymentProcessedReport';
import AllTypeTableNew from './Components/MyAccount/AllTypeTableNew';
import ResendEmail from './Components/MyAccount/ResendEmail';
import BacklogUploader from './Components/MyAccount/BacklogUploader';
import BacklogTable from './Components/MyAccount/BacklogTable';
import Forgot from './Components/Forgot/Forgot';
import ResetPwd from './Components/MyAccount/ResetPwd';
import CheckReport from './Components/MyAccount/CheckReport';
import PhysicalCardReport from './Components/MyAccount/PhysicalCardReport';
import VirtualCardReport from './Components/MyAccount/VirtualCardReport';
import FundReport from './Components/MyAccount/FundReport';
import EditPayment from './Components/MyAccount/EditPayment';
import UnRedeemedReport from './Components/MyAccount/UnRedeemedReport';
import CSREditPayment from './Components/MyAccount/CSREditPayment';
import ChangeInfoReport from './Components/MyAccount/ChangeInfoReport';
import CheckOrder from './Components/MyAccount/CheckOrder';
import RicPaymentReport from './Components/MyAccount/RicPaymentReport';
import {LicenseManager} from "ag-grid-enterprise";
// import AllTypeTableAnt from './Components/MyAccount/antTables/AllTypeTableAnt';
import PeguTableAnt from './Components/MyAccount/antTables/PeguTableAnt';
import CustomerSatTableAnt from './Components/MyAccount/antTables/CustomerSatTableAnt';
import Footer from "./Components/MyAccount/Footer";
import InvoiceOrder from "./Components/MyAccount/InvoiceOrder";
import Users from "./Components/MyAccount/Users";
import VppReport from './Components/MyAccount/VppReport';
LicenseManager.setLicenseKey("CompanyName=Best Payment Solutions,LicensedGroup=Sushant Vengurlekar,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-030229,SupportServicesEnd=5_July_2023_[v2]_MTY4ODUxMTYwMDAwMA==490c8a68a273c19e10263e1cdba79e9c");


const httpLink = createUploadLink({
  uri: process.env.REACT_APP_NODE_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('currentUser');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_NODE_URL_WSS}`,
  options: {
    reconnect: true,
    /*connectionParams: {
      authToken: localStorage.getItem('isAuth'),
    }*/
  }
})

wsLink.subscriptionClient.on('connecting', () => {
  console.log('connecting');
});

wsLink.subscriptionClient.on('connected', () => {
  console.log('connected');
});

wsLink.subscriptionClient.on('reconnecting', () => {
  console.log('reconnecting');
});

wsLink.subscriptionClient.on('reconnected', () => {
  console.log('reconnected');
});

wsLink.subscriptionClient.on('disconnected', () => {
  console.log('disconnected');
});

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () => wsLink.subscriptionClient.maxConnectTimeGenerator.max;

/*const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  logoutLink.concat(authLink.concat(httpLink))
)*/
const errorlink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
    // authenticationService.logout();
    // history.push('/login');
    // window.location='/login';
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    console.log(response);
    //response.errors = null;
    authenticationService.logout();
    history.push('/login');
  }
});

const httpLinkErrHandling = ApolloLink.from([
  errorlink,
  httpLink,
])

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(httpLinkErrHandling)
)
/*const client = new ApolloClient({
  link: logoutLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache()
});*/
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});

/*function App() {
  return (
    <ApolloProvider client={client}>
      <React.Fragment>
        <Router>
          <NavigationBar />
          <Jumbotron />
          <Uploader />
        </Router>
      </React.Fragment>
    </ApolloProvider>
  );
}*/
class App extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          currentUser: null
      };
  }

  componentDidMount() {
      authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
  }

  logout() {
      authenticationService.logout();
      history.push('/login');
  }

  render() {
    const { currentUser } = this.state;
    return (
      <ApolloProvider client={client}>
        <React.Fragment>
          <Router history={history}>
            <PrivateRoute exact path="/uploader" component={Uploader} />
            <PrivateRoute exact path="/cussattable" component={CustomerSatTableAnt} />
            <PrivateRoute exact path="/pegutable" component={PeguTableAnt} />
            <PrivateRoute exact path="/" component={Uploader} />
            <PrivateRoute exact path="/alltypetable" component={AllTypeTableNew}  client={client} />
            <Route path="/login" component={LoginPage} />
            <Route exact path="/forgotpwd" component={Forgot} />
            <PrivateRoute exact path="/cussatreport" component={CustomerSatReport} />
            <PrivateRoute exact path="/pegureport" component={PeguReport} />
            <PrivateRoute exact path="/addresschangereport" component={AddressChangeReport} />
            <PrivateRoute exact path="/alltypereport" component={AllStatusReport} />
            <PrivateRoute exact path="/vpp-report" component={VppReport} />
            <PrivateRoute exact path="/lien-report" component={LienFilingReport} />
            <PrivateRoute exact path="/processed-report" component={PaymentProcessedReport} />
            <PrivateRoute exact path="/resendemail" component={ResendEmail} />
            <PrivateRoute exact path="/invoice-order" component={InvoiceOrder} />
            <PrivateRoute exact path="/backloguploader" component={BacklogUploader} />
            <PrivateRoute exact path="/backlogtable" component={BacklogTable} />
            <PrivateRoute exact path="/resetPwd" component={ResetPwd} />
            <PrivateRoute exact path="/checkreport" component={CheckReport} />
            <PrivateRoute exact path="/physicalreport" component={PhysicalCardReport} />
            <PrivateRoute exact path="/virtualreport" component={VirtualCardReport} />
            <PrivateRoute exact path="/fundReport" component={FundReport} />
            <PrivateRoute exact path="/editPayment" component={EditPayment} />
            <PrivateRoute exact path="/unredeemedreport" component={UnRedeemedReport} />
            <PrivateRoute exact path="/csreditPayment" component={CSREditPayment} />
            <PrivateRoute exact path="/changeInfoReport" component={ChangeInfoReport} />
            <PrivateRoute exact path="/checkOrder" component={CheckOrder} />
            <PrivateRoute exact path="/ricPaymentReport" component={RicPaymentReport} />
            <PrivateRoute exact path="/users" component={Users} />
          </Router>
          <Footer/>
        </React.Fragment>
      </ApolloProvider>
    );
}
}

export default App;

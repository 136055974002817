import React, { useState, useCallback, useEffect, Fragment } from "react";
import { Table, Tag, Space } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import NavigationBar from "./NavigationBar";
import Jumbotron from "./Jumbotron";
import LoadingOverlay from "react-loading-overlay";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Select,
  Modal,
} from "antd";
import AddUser from "./AddUser";
import { RoleName, getRoleFlagObject, roleOptions, statusOptions, removeGraphQLErrorPrefix } from "../../_helpers/utils";
import { useLazyQuery } from "@apollo/react-hooks";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const { Column } = Table;
export const GET_USERS = gql`
  query getAllUsers($searchQuery: String) {
    getAllUsers(searchQuery: $searchQuery) {
      _id
      email
      fname
      lname
      phone
      bpsadminFlag
      accountingFlag
      title
      registerDate
      status
      createdAt
      superUser
      resetPwdFlag
      managementFlag
    }
  }
`;

const RESEND_EMAIL = gql`
  mutation resendEnrollmentMailTOUser($email: String!) {
    resendEnrollmentMailTOUser(email: $email) {
      message
    }
  }
`;

const EDIT_USER = gql`
    mutation editUser($input: editInputUser) {
      editUser(input: $input) {
            id
          }
    }
`;

const Users = () => {
  const [spinnerDisplay, setSpinnerDisplay] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [loaderText, setLoaderText] = useState("Loading your users...");
  const [getAllUsers, { loading, data, error }] = useLazyQuery(GET_USERS, {
    onCompleted(data){
      setSpinnerDisplay(true);
      const tableData = [];
      data.getAllUsers.map((entry) => {
        const userEntry = {
          _id: entry._id,
          fname: entry.fname,
          lname: entry.lname,
          createdAt: formatDate(entry.createdAt),
          email: entry.email,
          role: entry.superUser ? RoleName.ROOT_ADMIN : entry.bpsadminFlag ? RoleName.SUPPORT_LEVEL : entry.accountingFlag ? RoleName.ACCOUNTING_LEVEL : entry.managementFlag ? RoleName.MANAGEMENT_LEVEL : RoleName.CLIENT_ADMIN,
          status: entry.status === "active" ? "Active" : "Inactive",
          resetPwdFlag: entry.resetPwdFlag,
        };
        setNewStatus(entry.status)
        tableData.push(userEntry);
      });
      setTableData(tableData);
      setSpinnerDisplay(false);
    },
    onError(err) {
      console.log(err);
    },
    variables:{
      searchQuery: ""
    }
  });
  const [resendEmail] = useMutation(RESEND_EMAIL);
  const [tabledata, setTableData] = useState([]);
  const [form] = Form.useForm();
  const [editUser] = useMutation(EDIT_USER, {
    onCompleted(data) {
      openNotificationWithIcon('success', 'User updated successfully!')
      setShowEdit(false)
      window.location.reload();

    },
    onError(error) {
      console.log(error);
      openNotificationWithIcon('error', removeGraphQLErrorPrefix(error.message))
    },
  });

  useEffect(() => {
    getAllUsers()
  }, []);

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    // Get month, day, and year
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    // Format as MM-DD-YYYY
    const formattedDate = `${month}-${day}-${year}`;

    return formattedDate;
  }

  const editUserAction = async (data) => {
    form.setFieldsValue({
      _id: data._id,
      fname: data.fname,
      lname: data.lname,
      email: data.email,
      roleName: data.role,
      status: data.status,
    });

    setShowEdit(true);
  };

  const handleStatusChange = (value) =>{
    setNewStatus(value);
  }

  const onFinish = (values) => {
    const apiPayload = {
      input: {
        _id: values._id,
        fname: values.fname,
        lname: values.lname,
        email: values.email,
        status: newStatus,
        ...getRoleFlagObject(values.roleName)
      }
    }

    editUser({
      variables: apiPayload,
    })
  };

  const resendEmailAction = async (email) => {
    try {
      const res = await resendEmail({
        variables: {
          email,
        },
      });
      if (res) {
        openNotificationWithIcon("success", "Email sent successfully!");
      }
    } catch (err) {
      console.log(err);
      openNotificationWithIcon("error", "Error while sending the email!");
    }
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    });
  };


  function onSearchSomething(value){
    getAllUsers({
      variables:{
        searchQuery: value
      }
    });
  }

  const headerOrder = [
    'First Name',
    'Last Name',
    'Email',
    'Role',
    'Status'
  ];

  function exportUsers() {
    const userData = [];
    const fileName = "SPP-Users-";
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    tabledata.map(user => {
      user['First Name'] = user['fname']
      user['Last Name'] = user['lname']
      user['Email'] = user['email']
      user['Role'] = user['role']
      user['Status'] = user['status']
      userData.push({
        'First Name': user['First Name'],
        'Last Name': user['Last Name'],
        'Email': user['Email'],
        'Role': user['Role'],
        'Status': user['Status']
      })
    })
    const ws = XLSX.utils.json_to_sheet(userData, { header: headerOrder });
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    let data2 = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data2, fileName + formatDate(new Date()) + fileExtension);
  }

  return (
    <>
      <LoadingOverlay
        active={spinnerDisplay}
        spinner
        text={loaderText}
      >
        <NavigationBar />
        <Jumbotron />
        <br />
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>Users</h2>
          <Row>
            <Col>
            <Form style={{maxWidth:600}}>
                    <Form.Item name="search" label="Search">
                        <Input.Search allowClear={true} onSearch={onSearchSomething}/>
                    </Form.Item>
          </Form>
            </Col> &nbsp;&nbsp;&nbsp;&nbsp;
            <Col>
            <div style={{ marginLeft: 0 }}>
            <AddUser />
          </div>
            </Col> &nbsp;&nbsp;&nbsp;&nbsp;
            <Col>
            <Button onClick={exportUsers}>Export Users</Button>
            </Col>
          </Row>
        </div>
        <center>
        <Modal
              visible={showEdit}
              onCancel={() => setShowEdit(false)}
              className="w-50"
              maskStyle={{
                backgroundColor: "rgb(0, 0, 0, 0.3)",
              }}
              footer={null}
              destroyOnClose={true}
              title="Edit User"
            >
              <Form form={form} onFinish={onFinish}>
                <div className="shadow-sm border rounded p-4">
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        label="First Name"
                        name="fname"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please enter first name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        label="Last Name"
                        name="lname"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please enter last name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        hidden={true}
                        label="ID"
                        name="_id"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please enter an email address",
                          },
                          {
                            type: "email",
                            message: "Invalid email format",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        label="Role"
                        name="roleName"
                        rules={[
                          {
                            required: true,
                            message: "Please select a role",
                          },
                        ]}
                      >
                        <Select options={roleOptions} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        label="Status"
                        name="status"
                        rules={[
                          {
                            required: true,
                            message: "Please select status",
                          },
                        ]}
                      >
                        <Select options={statusOptions} onChange={(e) => handleStatusChange(e)}/>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <br />
                <center>
                  <Button variant="primary" htmlType="submit">
                    Save
                  </Button>
                </center>
              </Form>
            </Modal>
        </center>
        {contextHolder}
        <Row>
          <Col span={16} offset={4}>
            <Table dataSource={tabledata}>
              <Column title="First Name" dataIndex="fname" key="fname" />
              <Column title="Last Name" dataIndex="lname" key="lname" />
              <Column title="Email" dataIndex="email" key="email" />
              <Column title="Role" dataIndex="role" key="role" />
              <Column title="Status" dataIndex="status" key="status" />

              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <Space size="middle">
                    {<a onClick={() => editUserAction(record)}>Edit</a>}
                    {record.resetPwdFlag && record.status === 'Active' && (
                      <a onClick={() => resendEmailAction(record.email)}>
                        Resend Email
                      </a>
                    )}
                  </Space>
                )}
              />
            </Table>
          </Col>
        </Row>
      </LoadingOverlay>
    </>
  );
};

export default Users;

import gql from "graphql-tag";
import { formatDate, convertTimestampToMMDDYYYY } from '../../_helpers/utils';
const query = gql`
  query getAllTypeTableDataChunks ($allTypeInput: allTypeInput){
    getAllTypeTableDataChunks(input: $allTypeInput) {
        allTypeTableData {
            paymentId
            paymentType
            amount
            cusLeaseNo
            guaranteeYr
            fullName
            email
            phone
            address {
                address1
                address2
                city
                state
                zip
                addressType
            }
            redemptionDate
            cardType
            ownHome
            orderId
            orderDate
            shipDate
            shipMethod
            token
            cusLeaseNo
            addrChangeReason
            autoRedeemed
            checkAction
            reissueDate
            doesNoteExist
            attentionTo
            invoiceOrderFundingDate
            upsTracking
        }
        pageInfo {
          totalDocs
        }
    }
  }
`; 

class ServerSideDatasource {
  constructor(client, cacheBlockSize, quickFilterRef, quickOptionFilterRef) {
    this.client = client;
    this.cacheBlockSize = cacheBlockSize;
    this.quickFilterRef = quickFilterRef;
    this.quickOptionFilterRef = quickOptionFilterRef;
  }

  getRows(params) {
      //console.log(params);
      //console.log(this.quickFilterRef.current.value);
    let jsonRequest = JSON.stringify(params.request, null, 2);
    //console.log(jsonRequest);
    //const filterText = JSON.stringify(params.request.filterModel);
    const filterText = this.quickFilterRef.current.value;
    const filterTextOption = this.quickOptionFilterRef.current.value;

    // query GraphQL endpoint
    this.client.query({
        query,
        variables: {allTypeInput: {startRow: params.request.startRow, endRow: params.request.endRow, sorting: params.request.sortModel, filterText, filterTextOption}}
      })
      .then(response => {
          //console.log(response);
        const rows = response.data.getAllTypeTableDataChunks[0].allTypeTableData;
        let tempRowData = [];
        rows.map(paymentEntry => {
            let payment = {paymentId: paymentEntry.paymentId, fullName:paymentEntry.fullName, email:paymentEntry.email, amount: '$'+paymentEntry.amount, phone: paymentEntry.phone,
            redemptionDate: paymentEntry.redemptionDate, cardType: paymentEntry.cardType, ownHome: paymentEntry.ownHome, paymentType: paymentEntry.paymentType,
            orderId: paymentEntry.orderId, orderDate: formatDate(paymentEntry.orderDate), guaranteeYr: paymentEntry.guaranteeYr, shipDate: paymentEntry.shipDate, 
            shipMethod: paymentEntry.shipMethod, cusLeaseNo: paymentEntry.cusLeaseNo, token: paymentEntry.token, autoRedeemed: paymentEntry.autoRedeemed,
            checkAction: paymentEntry.checkAction, reissueDate: paymentEntry.reissueDate, doesNoteExist: paymentEntry.doesNoteExist, attentionTo: paymentEntry.attentionTo, 
            invoiceOrderFundingDate: paymentEntry.invoiceOrderFundingDate ? convertTimestampToMMDDYYYY(paymentEntry.invoiceOrderFundingDate): "",
            upsTracking: paymentEntry.upsTracking ? paymentEntry.upsTracking.reverse() : [],
            reissuePaymentMethod: paymentEntry.reissueDate ? paymentEntry.shipMethod : ""
          }
            paymentEntry.address.map(addressEntry => {
                let prefix = 'm';
                if (addressEntry.addressType === 'install') {
                    prefix = 'i';
                } else {
                    prefix = 'm';
                }
                payment[prefix+'address1'] = addressEntry.address1;
                payment[prefix+'address2'] = addressEntry.address2;
                payment[prefix+'city'] = addressEntry.city;
                payment[prefix+'state'] = addressEntry.state;
                payment[prefix+'zip'] = addressEntry.zip;
            })
            payment['addrChangeReason'] = paymentEntry.addrChangeReason;
            tempRowData.push(payment);
        });

        // determine last row to size scrollbar and last block size correctly
        let lastRow = -1;
        if (rows.length <= this.cacheBlockSize) {
          lastRow = params.request.startRow + rows.length;
        }

        // pass results to grid
        params.success({ rowData: tempRowData, rowCount: response.data.getAllTypeTableDataChunks[0].pageInfo.totalDocs });
      })
      .catch(err => {
        console.error(err);
        params.fail();
      });
  }
}

export default ServerSideDatasource;
import React, { useState, useCallback, useEffect, Fragment } from "react";
import { useDropzone } from "react-dropzone";
import gql from "graphql-tag";
import { useMutation, useSubscription } from "@apollo/react-hooks";
import styled from 'styled-components';
//import { Alert } from 'reactstrap';
//import sunpowerSubscription from '../../subscriptions/sunpower';
import backlogUploaderSubscription from '../subscriptions/BacklogUploader';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import { Alert, Container, Row, Col } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser'; 

const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isDragActive) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

const uploadFileMutation = gql`
  mutation UploadBacklog($file: Upload!) {
    uploadBacklog(file: $file)
  }
`;

const BacklogUploader = () => {
    
  const [uploadFile, { mutatedata, error }] = useMutation(uploadFileMutation);
  const onDrop = useCallback(
    ([file]) => {
        console.log(file);
      try {
        uploadFile({ variables: { file } }).then(
          res => {
              
          },
          err => {
            //console.log('error caught: ',err); 
          }
          );
        setshowInitialMessage(true);
      } catch(err) {
        console.log('in uploader err: ',err);
      }
      
    },
    [uploadFile]
  );
  if (error) {
    console.log('Error in uploader: ',error);
  }
  const [showInitialMessage, setshowInitialMessage] = useState(false);
  const [showValidationMessage, setshowValidationMessage] = useState(false);
  const [showInfoMessage, setshowInfoMessage] = useState(false);
  const [showSuccessMessage, setshowSuccessMessage] = useState(false);
  const [validationMessage, setvalidationMessage] = useState('');
  const [infoMesage, setinfoMesage] = useState('');
  const [successMessage, setsuccessMessage] = useState('');
  const [subscriptionMessage, setSubscriptionMessage] = useState({ display: false });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const {data, loading} = useSubscription(backlogUploaderSubscription, {
    variables: {},
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data) {
        console.log(data['backlogFileUpdateStatus']);
        let errorMessages = data['backlogFileUpdateStatus']['message'].split("|");
        console.log(errorMessages.length);
          //setSuccessMessage({display: false}); 
          /*setSubscriptionMessage({display: true})
          window.setTimeout(()=>{
            setSubscriptionMessage({display: false})
          },10000)*/
          setshowInitialMessage(false);
          if (data['backlogFileUpdateStatus']['type'] === 'error') {
            setshowValidationMessage(true);
            const validationMessages = '<p>File not Processed</p>'+errorMessages.join('<p></p>');
            setvalidationMessage(validationMessages);

          } else if(data['backlogFileUpdateStatus']['type'] === 'info') {
            setshowValidationMessage(false);
            setshowInfoMessage(true);
            const infoMessages = errorMessages.join('<p></p>');
            setinfoMesage(infoMessages);
          } else if(data['backlogFileUpdateStatus']['type'] === 'success') {
            setshowInfoMessage(false);
            setshowSuccessMessage(true);
            const successMessages = errorMessages.join('<p></p>');
            setsuccessMessage(successMessages);
          }
      }
    }
  });
  //const {data, loading} = useSubscription(addressVerificationSubscription, {});
  /*useEffect(() => {
    if (!loading && data) {
        //console.info(data.addressVerificationDone.path);
        setAddressFile({display: true, url: data.addressVerificationDone.path })
        
        
    }
  });*/
  /*return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </div>
  );*/
  return (
    <Fragment>
      <NavigationBar />
      <Jumbotron />
        <br />
          <Container>
            <Row>
              <Col md={6}>
                <Alert color="secondary" style={{ display: ((showInitialMessage) ? 'block' : 'none') }}>
                Please wait till the file validaton is complete.
              </Alert>
              <Alert color="danger" style={{ display: ((showValidationMessage) ? 'block' : 'none') }}>
                { ReactHtmlParser (validationMessage) }
              </Alert>
              <Alert color="info" style={{ display: ((showInfoMessage) ? 'block' : 'none') }}>
                {infoMesage}
              </Alert>
              <Alert color="success" style={{ display: ((showSuccessMessage) ? 'block' : 'none') }}>
                {successMessage}
              </Alert>
              </Col>
            </Row>
            
          </Container>
        <div className="container">
            <Container2 {...getRootProps({isDragActive})}>
            <input {...getInputProps()} />
            
            <p>Drag 'n' drop file for Backlog, or click to select files</p>
            </Container2>
        </div>
        <br />
        
    </Fragment>
  );
};

export default BacklogUploader;
import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { Table, Tag, Space } from 'antd';
import gql from "graphql-tag";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import 'antd/dist/antd.css';
import {
    Form, Input,Button, Row, Col
  } from 'antd';
import { Alert } from 'reactstrap';
import { formatAmount, convertTimestampToMMDDYYYY } from '../../_helpers/utils';

const { Column, ColumnGroup } = Table;
const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 32,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  const query = gql`
  query listInvoiceOrders {
    listInvoiceOrders {
        reportId
        fileName
        created
        status
        reportKey
        funded
        reportTotal
        fundedAt
    }
  }
`;


const mutation = gql`
    mutation fundInvoiceOrderById($orderId: String){
        fundInvoiceOrderById(orderId: $orderId) {
            reportId
            fileName
            created
            status
            reportKey
            funded
            reportTotal
            fundedAt
        }
    }
`;
const InvoiceOrder = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(true);
    const [spinnerDisplay2, setSpinnerDisplay2] = useState(false);
    const [loaderText, setLoaderText] = useState('Loading your invoice orders...');
    let {loading, data, error} = useQuery(query);
    const [tabledata, setTableData] = useState([]);
    const [showSuccessMessage, setshowSuccessMessage] = useState(false);
    const [showErrorMessage, setshowErrorMessage] = useState(false);
    const client = useApolloClient();
    
    useEffect(() => {
        if (data && !loading && spinnerDisplay) {
            console.log(data);
            const tableData = [];
            data.listInvoiceOrders.map(entry => {
                if (entry.status === 'success') {
                    const orderEntry = {
                        reportId: entry.reportId,
                        fileName: entry.fileName,
                        created: entry.created,
                        reportKey: entry.reportKey,
                        funded: entry.funded,
                        reportTotal: entry.reportTotal ? formatAmount(entry.reportTotal) : '',
                        fundedAt: entry.fundedAt ? convertTimestampToMMDDYYYY(entry.fundedAt) : ""
                    };
                    console.log()
                    tableData.push(orderEntry);
                }
                
            })
            setTableData(tableData);
            setSpinnerDisplay(false);
        }
    }, [spinnerDisplay, data, loading]);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    const [orderFunding] = useMutation(mutation);

    const orderToFund = (orderId) => {
        setSpinnerDisplay2(true);
        setLoaderText('Funding your invoice order...');
        orderFunding({
            variables: { orderId: orderId }
            }).then(
            res => {
                console.log('res: ',res);
                if (res) {
                    setshowSuccessMessage(true);
                    setSpinnerDisplay2(false);
                    const tableData = [];
                    res.data.fundInvoiceOrderById.map(entry => {
                        if (entry.status === 'success') {
                            const orderEntry = {
                                reportId: entry.reportId,
                                fileName: entry.fileName,
                                created: entry.created,
                                reportKey: entry.reportKey,
                                funded: entry.funded,
                                reportTotal: entry.reportTotal ? formatAmount(entry.reportTotal) : '',
                                fundedAt: entry.fundedAt ? convertTimestampToMMDDYYYY(entry.fundedAt) : ""
                            };
                            tableData.push(orderEntry);
                        }
                    });
                    setTableData(tableData);
                }
            },
            err => {
                console.log(err); 
                setshowErrorMessage(true);
            }
            );
    }

    return (
        <>
        <LoadingOverlay
            active={spinnerDisplay || spinnerDisplay2}
            spinner
            text={loaderText}
            >
        <NavigationBar />
        <Jumbotron />
            <br /> 
            <div className="container"> <h2>Invoice Order</h2>	</div>
           
                    <Row>
                        <Col span={12} offset={5}>
                        <Alert color="success" style={{ display: ((showSuccessMessage) ? 'block' : 'none') }}>
                              The invoice order was successfully funded.  
                        </Alert>
                        <Alert color="danger" style={{ display: ((showErrorMessage) ? 'block' : 'none') }}>
                            There was an error funding the invoice order.
                        </Alert>
                        </Col>
                    </Row>
               
                <Row>
                    <Col span={16} offset={4}> 
                        <Table dataSource={tabledata}>
                            <Column title="Order ID" dataIndex="reportId" key="reportId" />
                            <Column title="Date Created" dataIndex="created" key="created" />
                            <Column title="Order Total" dataIndex="reportTotal" key="reportTotal" />
                            <Column 
                                title="Download Link" 
                                dataIndex="fileName" 
                                key="fileName" 
                                render={(text, record) => (
                                    <span>
                                      <a href={record.fileName}>{record.fileName.includes('VPP') ? "Download VPP Payment Report" : "Download Report"}</a>
                                    </span>
                                 )}
                            />
                             <Column title="Funded Date" dataIndex="fundedAt" key="fundedAt" />
                            
                            <Column
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                {(!record.funded) ? <a onClick={() => orderToFund(record.reportKey)}>Fund Order</a> : <span>Funded</span> }
                                </Space>
                            )}
                            />
                        </Table>
                    </Col>
                </Row>
        
        </LoadingOverlay>
    </>
    );
}

export default InvoiceOrder;
import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useSubscription } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import {
    Form,
    InputNumber,
    Checkbox, Col, Row, DatePicker, Button
  } from 'antd';
import 'antd/dist/antd.css';
//import Download from './Download';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CheckBoxGrid from './CheckBoxGrid';

const Styles = styled.div`
    .ant-checkbox-group  {
        width: 100%;
    }
    .ant-btn-primary {
        background-color: orange;
    }
`;
const query = gql`
  query retrievePaymentInformation($paymentType: String, $startDate: String, $endDate: String, $adminFlag:Boolean){
    retrievePaymentInformation(paymentType: $paymentType, startDate: $startDate, endDate: $endDate, adminFlag: $adminFlag) {
        paymentType
        amount
        cusLeaseNo
        guaranteeYr
        fullName
        email
        phone
        address {
            address1
            address2
            city
            state
            zip
            addressType
        }
        redemptionDate
        cardType
        ownHome
        token
        orderId
        orderDate
        addrChangeReason
        checkAction
        reissueDate
        invoiceOrderFundingDate
        upsTracking
    }
  }
`;
const AllStatusReport = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    
    const formItemLayout = {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 24,
        },
      };
    const colLabels = {'redemptionDate' : 'Date Submission Entered', 'amount': 'Amount', 'token': 'Tracking Number', 'upsTracking': 'UPS Tracking',  'fullName': 'Name', 
        'email':'Email', 'phone': 'Phone', 'maddress1': 'New Address','maddress2': 'New Address2', 'mcity': 'New City', 'mstate': 'New State', 'mzip': 'New Zip', 'address1' : 'Address1',
    'address2': 'Address2', 'city': 'City', 'state':'State', 'zip':'Zip','guaranteeYr':'Guarantee Years','cusLeaseNo': 'Lease / Customer Number','cardType':'Reward Type', 'paymentType': 'Payment Type', 'orderId': 'Order Id', 
    'orderDate': 'Order Date', 'invoiceOrderFundingDate':'Email Sent Date', 'addrChangeReason': 'Address Change Reason', 'checkAction' : 'Check Action', 'reissueDate': 'Reissue Date'};
    const headerOrder = {'fullName': 'Name', 'email': 'Email', 'phone': 'Phone', 'paymentType': 'Payment Type', 'cardType': 'Reward Type', 'redemptionDate': 'Date Submission Entered', 'amount': 'Amount', 
        'token': 'Tracking Number', 'upsTracking': 'UPS Tracking', 'guaranteeYr':'Guarantee Years', 'cusLeaseNo': 'Lease / Customer Number', 'orderId': 'Order Id', 'orderDate': 'Order Date', 'invoiceOrderFundingDate':'Email Sent Date', 'address1': 'Address1',
        'address2': 'Address2', 'city': 'City', 'state':'State', 'zip':'Zip', 'maddress1': 'New Address','maddress2': 'New Address2', 'mcity': 'New City', 'mstate': 'New State', 'mzip': 'New Zip', 'addrChangeReason': 'Address Change Reason',
        'checkAction' : 'Check Action', 'reissueDate': 'Reissue Date'
    };
    
    const [reportDates, setReportDates] = useState({'sdate': '', "edate": ''});
    let [pullData, {loading, data }] = useLazyQuery(query, {
        fetchPolicy: 'network-only'
      });
    
    const fileName = "AllStatusReport";
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [reportCols, setReportCols] = useState({});
    
    useEffect(() => {
        
        setTimeout(()=>{
            if (data && !loading && spinnerDisplay) {
            
                //console.log(data);
                let tempRowData = [];
                
                data.retrievePaymentInformation.map(paymentEntry => {
                    let payment = {};
                    const keys = Object.keys(paymentEntry);
                    const addressFields = ['address1', 'address2', 'city', 'state', 'zip'];
                    //console.log(reportCols);
                    keys.map(colKey => {
                        if (colKey !== 'address') {
                            if (reportCols[colKey]) {
                                payment[colLabels[colKey]] = paymentEntry[colKey];
                            }
                        } else if (colKey === 'address') {
                            paymentEntry.address.map(addressEntry => {
                                const prefix = (addressEntry.addressType === 'mailing') ? 'm':'';
                                addressFields.map(akey => {
                                    const addressKey = prefix+akey;
                                    if (reportCols[addressKey]) {
                                        payment[colLabels[addressKey]] = addressEntry[akey];
                                    }
                                });
                            })
                        }
                    })
                    payment['UPS Tracking'] = paymentEntry.upsTracking ? paymentEntry.upsTracking.join(', ') : ""
                    tempRowData.push(payment);
                });
        
                const columnOrder = [];
                
                for (let key in headerOrder) {
                    //console.log(key,':',headerOrder[key]);
                    if (reportCols[key]) {
                        columnOrder.push(headerOrder[key]);
                    }
                }
                
                if (spinnerDisplay && tempRowData.length > 0) {
                    //console.log('tempRowData: ',tempRowData);
                    const ws = XLSX.utils.json_to_sheet(tempRowData, {header:columnOrder});
                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    let data2 = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data2, fileName + fileExtension);
                    data2 = undefined;
                }
                tempRowData = [];
                setSpinnerDisplay(false);
            }
        }, 1000);
        
    }, [data, loading]);
    

    const onFinish = async (values) => {
        //console.log(columns);
        //console.log(reportDates);
        setSpinnerDisplay(true);
        
        let result = await pullData({
            variables: {
                paymentType: '',
                startDate: reportDates['sdate'],
                endDate: reportDates['edate'],
                adminFlag: false
            }
        });
        
        
    };

    const startDateCapture = date => {
        console.log(date.format('YYYY-MM-DD'));
        let tempDate = reportDates;
        tempDate['sdate'] = date.format('YYYY-MM-DD');
        setReportDates(tempDate);
    }

    const endDateCapture = date => {
        console.log(date.format('YYYY-MM-DD'));
        let tempDate = reportDates;
        tempDate['edate'] = date.format('YYYY-MM-DD');
        setReportDates(tempDate);
    }

    
    const colNames = {'redemptionDate':'', 'amount':'', 'token':'','guaranteeYr':'','cusLeaseNo':'','fullName':'','email':'','phone':'','cardType':'','paymentType':'',
                    'address1':'', 'address2':'', 'city':'', 'state':'', 'zip':'', 'upsTracking': '',
                    'maddress1':'', 'maddress2':'', 'mcity':'', 'mstate':'', 'mzip':'', 'orderId': '', 'orderDate': '', 'addrChangeReason': '',
                    'checkAction' : '', 'reissueDate': '', 'invoiceOrderFundingDate': ''};

    const onCheckBoxChange = (colSelected) => {
        console.log(colSelected);
        setReportCols(colSelected);
    }

    const checkBoxList = [
        [
            {'id': 'redemptionDate', 'label':'Date Submission Entered'},
            {'id': 'amount', 'label':'Amount'},
            {'id': 'token', 'label':'Tracking Number'},
            {'id': 'guaranteeYr', 'label':'Guarantee Years'},
            {'id': 'cusLeaseNo', 'label':'Lease / Customer Number'},
            {'id': 'orderId', 'label':'Order Id'},
            {'id': 'upsTracking', 'label':'UPS Tracking'},
        ],
        [
            {'id': 'fullName', 'label':'Name'},
            {'id': 'email', 'label':'Email'},
            {'id': 'phone', 'label':'Phone'},
            {'id': 'cardType', 'label':'Reward Type'},
            {'id': 'paymentType', 'label':'Payment Type'},
            {'id': 'orderDate', 'label':'Order Date'}
        ],
        [
            {'id': 'address1', 'label':'Address1'},
            {'id': 'address2', 'label':'Address2'},
            {'id': 'city', 'label':'City'},
            {'id': 'state', 'label':'State'},
            {'id': 'zip', 'label':'Zip'},
            {'id': 'invoiceOrderFundingDate', 'label':'Email Sent Date'}
        ],
        [
            {'id': 'maddress1', 'label':'Mailing Address1'},
            {'id': 'maddress2', 'label':'Mailing Address2'},
            {'id': 'mcity', 'label':'Mailing City'},
            {'id': 'mstate', 'label':'Mailing State'},
            {'id': 'mzip', 'label':'Mailing Zip'},
            {'id': 'addrChangeReason', 'label':'Address Change Reason'},
            {'id': 'checkAction', 'label':'Check Action'},
            {'id': 'reissueDate', 'label':'Reissue Date'},
        ]
    ];

    

    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text='Loading your report data...'
                >
            <NavigationBar />
            <Jumbotron />
                <br />
            <Styles>
                
                
            <main>
                <div className="container">
	                <h2>All Status Reporting</h2>	          
                    <p>Reports will be produced and downloaded as Excel</p>
                    <Form
                        name="report"
                        {...formItemLayout}
                        onFinish={onFinish}
                        >
                    <div className="card">
                        <div className="card-body">
                            
                            <CheckBoxGrid
                                checkBoxList={checkBoxList}
                                onCheckBoxChange = {onCheckBoxChange}
                                colNames = {colNames}
                            />
                            
                            <div className="form-row bg-light p-2 mt-4">
                                <div className="col-md">
                                    <div className="form-group">
                                    <Form.Item label="Start Date" value="sdate">
                                        <DatePicker onChange={startDateCapture} />
                                    </Form.Item>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group">
                                    <Form.Item label="End Date" value="edate">
                                        <DatePicker onChange={endDateCapture} />
                                    </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <br />
                            <Row>
                                <Col span={6} offset={10}>
                                <Button type="primary" htmlType="submit" shape="round" size="large">
                                    Export Report
                                </Button>
                                </Col> 
                            </Row>

                            
                        </div>
                    </div>
                    </Form>
                </div>
                <br />
                
            </main>
            </Styles>
            </LoadingOverlay>
            
        </Fragment>
    );
}

export default AllStatusReport;
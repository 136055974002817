import React, {useState} from 'react';
import { Container, Grid, Row,Col } from 'react-bootstrap';

import { authenticationService } from '../../_services';

import styled from 'styled-components';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { graphql} from 'react-apollo';
import LoadingOverlay from 'react-loading-overlay'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Alert } from 'reactstrap';
import {useMutation} from "@apollo/react-hooks";

const Styles = styled.div`
    .navbar {
        background-color: #fff;
    }

    a, .navbar-brand, .navbar-nav .nav-link {
        color: #6c757d;

        &:hover {
            color: hsla(0,0%,0%,1.00);
            border-bottom: #F18B21 2px solid;
        }
        &:active {
            color: hsla(0,0%,0%,1.00);
            border-bottom: #F18B21 2px solid;
        }
    }

    .nav-link.tab-title {
        color: #858585;
        background-color: #EEEEEE;
    }

    .nav-link.tab-title:hover {
        color: #E2620E;
    }

    .nav-link.tab-title.active {
        color: #E2620E;
    }
    .jumbo {
        background-image: url("images/1200x627_banner no logo (1).png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        min-height: 30em;
    }
    .OrangeBtn {
        font-weight: 500;
        background-color: #F18B21;
        color: hsla(0,0%,100%,1.00);
    }
    .OrangeBtn:hover {
        background-color: #E2620E;
        color: hsla(0,0%,100%,1.00);
    }
    .buttonOrange {
        background-color: #F18B21;
        background-color: #E2620E;
        color: hsla(0,0%,100%,1.00);
        padding: 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        border-radius: 12px;
    }
    .buttonOrange:hover {
        background-color: #E2620E;
        background-color: #E2620E;
        color: hsla(0,0%,100%,1.00);
        padding: 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        border-radius: 12px;
    }

    .square_btn{
        display: inline-block;
        padding: 7px 20px;
        border-radius: 25px;
        text-decoration: none;
        hsla(0,0%,100%,1.00);
        background-image: -webkit-linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
        background-image: linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
        transition: .4s;
    }
    
    .square_btn:hover {
        display: inline-block;
        padding: 7px 20px;
        border-radius: 25px;
        text-decoration: none;
        hsla(0,0%,100%,1.00);
        background-image: -webkit-linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
        background-image: linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
        transition: .4s;
    }

    div.dangerMsg {
        color:red;
    }
`;

const mutation = gql`
    mutation forgotPwd($email: String) {
        forgotPwd(email: $email) 
    }
`;

const Forgot = (props) => {
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [forgotPwd] = useMutation(mutation);
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);

    const onFinish = (values) => {
        setSpinnerDisplay(true);
        forgotPwd({
            variables: {
                email: values.email
            }
            }).then(
            res => {
                //console.log(res);
                setSpinnerDisplay(false);
                if (res.data.forgotPwd) {
                    setSuccessMessage(true);
                    setErrorMessage(false);
                } else {
                    setSuccessMessage(false);
                    setErrorMessage(true);
                }
                
            },
            err => {console.log(err); }
            );
    }

    return (
        <LoadingOverlay
            active={spinnerDisplay}
            spinner
            text='Sending temporary password...'
            >
            <Styles>
                <Navbar expand="lg">
                <Navbar.Brand href="/"><img src="images/SunPower.png" width="180px" alt="logo"/></Navbar.Brand>
                </Navbar>
                <Container className="jumbo">
                    <Row>
                        <Col lg={6} md={{ size: 6, offset: 3 }} className="m-6 shadow-sm p-6">
                            <br />
                            <br />
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={{ size: 6, offset: 3 }} className="m-6 shadow-sm p-6">
                        <div className="card align-self-center">
                            <div className="card-header">
                                <h3 className="text-center">Forgot Password</h3>
                            </div>
                            <div className="card-body">
                            <Alert color="danger" className='dangerMsg' style={{ display: ((errorMessage) ? 'block' : 'none') }}>
                                The email doesn't exist in the system
                            </Alert>
                            <Alert color="success" style={{ display: ((successMessage) ? 'block' : 'none') }}>
                                You will receive an email with a temporary password which you can use to login. Click to <a href='/login'>Login</a>
                            </Alert>
                                <Form
                                name="normal_login"
                                className="login-form"
                                onFinish={onFinish}
                                size="large"
                                >
                                <div class="form-group">
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your Email!' }]}
                                >
                                    <Input prefix={<UserOutlined  />} placeholder="Email" />
                                </Form.Item>
                                </div>
                                
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="square_btn" shape="round" size="large">
                                    Forgot Password
                                    </Button>
                                </Form.Item>
                                
                                </Form>
                            </div>
                        </div>
                        
                            
                            
                        
                        </Col>
                    
                    </Row>	
                </Container>
            </Styles>
        </LoadingOverlay>
    );
}

export default Forgot;
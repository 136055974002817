import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
//import { Alert, Container, Row, Col } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import 'antd/dist/antd.css';
import { Table,Form,  Input ,Button, Row, Col} from 'antd'
import axios from 'axios';
import { authenticationService } from '../../_services';
import ResizableTitle from './ColumnResize';
import './BacklogTable.css';

const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 32,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

const query = gql`
  query getBacklogTabulatedData ($tableInput: tableInput){
    getBacklogTabulatedData(input: $tableInput) {
        backlogTableData {
            sno
            contractNo
            branchCode
            cusName
            mailingaddress
            amount
            remittanceType
            paymentMemo
            processingDate
            checkPPayNo
            checkStatus
            dateCashed
            status
            key
        }
        pageInfo {
            totalDocs
            totalPages
            page
            pagingCounter
            hasPrevPage
            hasNextPage
            prevPage
            nextPage
        }
    }
  }
`;

const backlogQUery = (searchString, pageNo, pageSize, sortColumn, sortDir) => `
  query {
    getBacklogTabulatedData(input: {searchString: "${searchString}", pageNo: ${pageNo}, pageSize: ${pageSize}, sortColumn: "${sortColumn}", sortDir: "${sortDir}"}) {
        backlogTableData {
            sno
            contractNo
            branchCode
            cusName
            mailingaddress
            amount
            remittanceType
            paymentMemo
            processingDate
            checkPPayNo
            checkStatus
            dateCashed
            status
            key
        }
        pageInfo {
            totalDocs
            totalPages
            page
            pagingCounter
            hasPrevPage
            hasNextPage
            prevPage
            nextPage
        }
    }
  }
`

const axiosGraphQL = axios.create({
    baseURL: process.env.REACT_APP_NODE_URL,
  });




const BacklogTable = (props) => {
    const { onResize, width, ...restProps } = props;
    const pageSize = 200;
    
    const [api, setApi] = useState(null);
    const [paginationOptions, setPaginationOptions] = useState({});
    const [spinnerDisplay, setSpinnerDisplay] = useState(true);
    const [spinnerDisplay2, setSpinnerDisplay2] = useState(false);
    const [spinnerDisplay3, setSpinnerDisplay3] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [sortOptions, setsortOptions] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [nextPage, setNextPage] = useState('');
    const [searchString, setSearchString] = useState('');
    let {loading, data, error} = useQuery(query,{
        variables: {tableInput: { searchString: '', pageNo: 1, pageSize: 100, sortColumn: 'createdAt', sortDir: 'desc' }},
      });
    //const [tracker, { loading, data }] = useLazyQuery(query);

    const [columns, setColumns] = useState([
        {
            title: 'Serial No',
            dataIndex: 'sno',
            key: 'sno',
            sorter: true,
            width: 100,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(0),
            })
        },
        {
            title: 'Contract No',
            dataIndex: 'contractNo',
            key: 'contractNo',
            sorter: true,
            width: 100,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(1),
            })
        },
        {
            title: 'Branch Code',
            dataIndex: 'branchCode',
            key: 'branchCode',
            sorter: true,
            width: 100,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(2),
            })
        },
        {
            title: 'Customer Name',
            dataIndex: 'cusName',
            key: 'cusName',
            sorter: true,
            width: 100,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(3),
            })
        },
        {
            title: 'Mailing Address',
            dataIndex: 'mailingaddress',
            key: 'mailingaddress',
            width: 300,
            sorter: true,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(4),
            })
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: true,
            width: 100,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(5),
            })
        },
        {
            title: 'Remittance Type',
            dataIndex: 'remittanceType',
            key: 'remittanceType',
            sorter: true,
            width: 100,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(6),
            })
        },
        {
            title: 'Payment Memo',
            dataIndex: 'paymentMemo',
            key: 'paymentMemo',
            sorter: true,
            width: 100,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(7),
            })
        },
        {
            title: 'Processing Date',
            dataIndex: 'processingDate',
            key: 'processingDate',
            sorter: true,
            width: 100,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(8),
            })
        },
        {
            title: 'Check No',
            dataIndex: 'checkPPayNo',
            key: 'checkPPayNo',
            width: 100,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(9),
            })
        },
        {
            title: 'Check Status',
            dataIndex: 'checkStatus',
            key: 'checkStatus',
            width: 100,
            onHeaderCell: column => ({
                width: column.width,
                onResize: handleResize(10),
            })
        },
        {
            title: 'Date Cashed',
            dataIndex: 'dateCashed',
            key: 'dateCashed',
            sorter: true
        },
      ]);

    const handleResize = index => (e, { size }) => {
        e.stopImmediatePropagation();
        setColumns((columns) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };
            return nextColumns;
        });
    };

    
    const components = {
        header: {
          cell: ResizableTitle,
        },
    };
    
    useEffect(() => {
        
        if (!loading && !error  && spinnerDisplay) {
            console.log('qdata: ',data);
            let tempRowData = [];
            setTotalCount(data.getBacklogTabulatedData.pageInfo.totalDocs);
            /*if (data.getBacklogTabulatedData.pageInfo.hasNextPage) {
                setNextPage(data.retrieveBacklog.pageInfo.nextPageCursor);
            } else {
                setNextPage(false);
            }*/
            
            data.getBacklogTabulatedData.backlogTableData.map(backlogEntry => {
                const backLogData = {
                    sno: backlogEntry['sno'],
                    contractNo: backlogEntry['contractNo'],
                    branchCode: backlogEntry['branchCode'],
                    cusName: backlogEntry['cusName'],
                    mailingaddress: backlogEntry['mailingaddress'],
                    amount: backlogEntry['amount'],
                    remittanceType: backlogEntry['remittanceType'],
                    paymentMemo: backlogEntry['paymentMemo'],
                    processingDate: backlogEntry['processingDate'],
                    checkPPayNo: backlogEntry['checkPPayNo'],
                    checkStatus: backlogEntry['checkStatus'],
                    dateCashed: backlogEntry['dateCashed'],
                    key: backlogEntry['key']
                }
                tempRowData.push(backLogData);
            });
            //console.log(data.retrieveBacklog.totalCount);
            setRowData(tempRowData);
            setSpinnerDisplay(false);
            
        }
    })

    const pagination = { 
        pageSize, 
        total: totalCount,
        showSizeChanger: false,
    };

    const onFinish = values => {
        console.log('Success:', values);
        if (values.searchString.length === 0) {
            window.location.reload();
        } else {
            setSearchString(values.searchString);
        }
        
        const sortDir = (sortOptions && sortOptions.order === 'ascend') ? 'asc' : 'desc';
        const sortCol = (sortOptions && sortOptions.field) ? sortOptions.field : 'createdAt';
        const pageNo = (paginationOptions && paginationOptions.current) ? paginationOptions.current : 1;
        setSpinnerDisplay2(true);
        try {
            const token = localStorage.getItem('currentUser');
            axiosGraphQL
            .post('', {
            query: backlogQUery(values.searchString, parseInt(pageNo),parseInt(pageSize), sortCol, sortDir),
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }})
            .then(result => {
                console.log(result);
                if (result.data.errors && result.data.errors[0]['message']) {
                    authenticationService.logout();
                    window.location='/login';
                } else {
                    setTotalCount(result.data.data.getBacklogTabulatedData.pageInfo.totalDocs);
                    let tempRowData = [];
                    
                    result.data.data.getBacklogTabulatedData.backlogTableData.map(backlogEntry => {
                        const backLogData = {
                            sno: backlogEntry['sno'],
                            contractNo: backlogEntry['contractNo'],
                            branchCode: backlogEntry['branchCode'],
                            cusName: backlogEntry['cusName'],
                            mailingaddress: backlogEntry['mailingaddress'],
                            amount: backlogEntry['amount'],
                            remittanceType: backlogEntry['remittanceType'],
                            paymentMemo: backlogEntry['paymentMemo'],
                            processingDate: backlogEntry['processingDate'],
                            checkPPayNo: backlogEntry['checkPPayNo'],
                            checkStatus: backlogEntry['checkStatus'],
                            dateCashed: backlogEntry['dateCashed'],
                            key: backlogEntry['key']
                        }
                        tempRowData.push(backLogData);
                    });
                    //console.log(data.retrieveBacklog.totalCount);
                    setRowData(tempRowData);
                    setSpinnerDisplay2(false);
                }
                
            });
        } catch (err) {
            console.log(err);
            console.log('failed');
        }
    };

    const onAction = (pagination, filters, sorter, extra) => {
        /*console.log(pagination);
        console.log(sorter);
        console.log(extra.action);*/
        setPaginationOptions(pagination);
        setsortOptions(sorter);
        setSpinnerDisplay2(true);
        const sortDir = (sorter.order === 'ascend') ? 'asc' : 'desc';
        const sortCol = (sorter && sorter.field) ? sorter.field : 'createdAt';
        try {
            const token = localStorage.getItem('currentUser');
            axiosGraphQL
            .post('', {
            query: backlogQUery(searchString, parseInt(pagination.current),parseInt(pagination.pageSize), sortCol, sortDir),
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }})
            .then(result => {
                console.log(result);
                if (result.data.errors && result.data.errors[0]['message']) {
                    authenticationService.logout();
                    window.location='/login';
                } else {
                    setTotalCount(result.data.data.getBacklogTabulatedData.pageInfo.totalDocs);
                    let tempRowData = [];
                    
                    result.data.data.getBacklogTabulatedData.backlogTableData.map(backlogEntry => {
                        const backLogData = {
                            sno: backlogEntry['sno'],
                            contractNo: backlogEntry['contractNo'],
                            branchCode: backlogEntry['branchCode'],
                            cusName: backlogEntry['cusName'],
                            mailingaddress: backlogEntry['mailingaddress'],
                            amount: backlogEntry['amount'],
                            remittanceType: backlogEntry['remittanceType'],
                            paymentMemo: backlogEntry['paymentMemo'],
                            processingDate: backlogEntry['processingDate'],
                            checkPPayNo: backlogEntry['checkPPayNo'],
                            checkStatus: backlogEntry['checkStatus'],
                            dateCashed: backlogEntry['dateCashed'],
                            key: backlogEntry['key']
                        }
                        tempRowData.push(backLogData);
                    });
                    //console.log(data.retrieveBacklog.totalCount);
                    setRowData(tempRowData);
                    setSpinnerDisplay2(false);
                }
                
            });
        } catch (err) {
            console.log(err);
            console.log('failed');
        }
    }

    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay || spinnerDisplay2 || spinnerDisplay3}
                spinner
                text='Loading your content...'
                >
                <NavigationBar />
                <Jumbotron />
                    <br />
                    <Form
                    {...layout}
                    name="Search"
                    onFinish={onFinish}
                    >
                    <Row>
                    <Col span={12} offset={5}>
                        <Form.Item
                            name="searchString"
                            
                        >
                            <Input placeholder="Search by contact no, name, payment memo, check no, branch code or date cashed"/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                            Search
                            </Button>
                        </Form.Item>
                    </Col>
                    </Row>
                    </Form>
               
                <Row>
                    <Col span={16} offset={4}>
                            <Table bordered components={components} columns={columns} dataSource={rowData} onChange={onAction} scroll={{ x: 1500, y: 600 }} pagination={pagination} rowKey="key" />

                    </Col>
                </Row>
                        
                    
                    
                    <br />
                </LoadingOverlay>
        </Fragment>
      );
}

export default BacklogTable;
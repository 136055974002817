import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { Table, Tag, Space } from 'antd';
import gql from "graphql-tag";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import 'antd/dist/antd.css';
import {
    Form, Input,Button, Row, Col
  } from 'antd';
import { Alert } from 'reactstrap';
import EditForm from './EditForm';
import { formatAmount } from '../../_helpers/utils';

const { Column, ColumnGroup } = Table;
const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 32,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  const query = gql`
  query listReportOrders {
    listReportOrders {
        reportId
        fileName
        created
        updatedAt
        status
        reportKey
        funded
        reportTotal
    }
  }
`;

const mutation = gql`
    mutation fundReportId($reportId: String){
        fundReportId(reportId: $reportId) {
            reportId
            fileName
            created
            updatedAt
            status
            reportKey
            funded
            reportTotal
        }
    }
`;
const FundReport = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(true);
    const [spinnerDisplay2, setSpinnerDisplay2] = useState(false);
    const [loaderText, setLoaderText] = useState('Loading your reported orders...');
    let {loading, data, error} = useQuery(query);
    const [tabledata, setTableData] = useState([]);
    const [showSuccessMessage, setshowSuccessMessage] = useState(false);
    const [showErrorMessage, setshowErrorMessage] = useState(false);
    const client = useApolloClient();
    
    useEffect(() => {
        if (data && !loading && spinnerDisplay) {
            console.log(data);
            const tableData = [];
            data.listReportOrders.map(entry => {
                if (entry.status === 'success') {
                    const orderEntry = {
                        reportId: entry.reportId,
                        fileName: entry.fileName,
                        created: entry.created,
                        updatedAt: entry.updatedAt,
                        reportKey: entry.reportKey,
                        funded: entry.funded,
                        reportTotal: formatAmount(entry.reportTotal)
                    };
                    console.log()
                    tableData.push(orderEntry);
                }
                
            })
            setTableData(tableData);
            setSpinnerDisplay(false);
        }
    }, [spinnerDisplay, data, loading]);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    const [orderFunding] = useMutation(mutation);
    

    const orderToFund = (orderId) => {
        setSpinnerDisplay2(true);
        setLoaderText('Funding your report order...');
        orderFunding({
            variables: { reportId: orderId }
            }).then(
            res => {
                console.log('res: ',res);
                if (res) {
                    setshowSuccessMessage(true);
                    setSpinnerDisplay2(false);
                    const tableData = [];
                    res.data.fundReportId.map(entry => {
                        if (entry.status === 'success') {
                            const orderEntry = {
                                reportId: entry.reportId,
                                fileName: entry.fileName,
                                created: entry.created,
                                updatedAt: entry.updatedAt,
                                reportKey: entry.reportKey,
                                funded: entry.funded,
                                reportTotal: formatAmount(entry.reportTotal)
                            };
                            tableData.push(orderEntry);
                        }
                    });
                    setTableData(tableData);
                }
            },
            err => {
                console.log(err); 
                setshowErrorMessage(true);
            }
            );
    }

    return (
        <>
        <LoadingOverlay
            active={spinnerDisplay || spinnerDisplay2}
            spinner
            text={loaderText}
            >
        <NavigationBar />
        <Jumbotron />
            <br /> 
                    <Row>
                        <Col span={12} offset={5}>
                        <Alert color="success" style={{ display: ((showSuccessMessage) ? 'block' : 'none') }}>
                              The report order was successfully funded.  
                        </Alert>
                        <Alert color="danger" style={{ display: ((showErrorMessage) ? 'block' : 'none') }}>
                            There was an error funding the report order.
                        </Alert>
                        </Col>
                    </Row>
               
                <Row>
                    <Col span={16} offset={4}> 
                        <Table dataSource={tabledata}>
                            <Column title="Report ID" dataIndex="reportId" key="reportId" />
                            <Column title="Date Created" dataIndex="created" key="created" />
                            <Column title="Report Total" dataIndex="reportTotal" key="reportTotal" />
                            <Column 
                                title="Download Link" 
                                dataIndex="fileName" 
                                key="fileName" 
                                render={(text, record) => (
                                    <span>
                                      {(localStorage.getItem('superUser') === 'true' || record.funded) ? <a href={record.fileName}>Download Report</a> : ""}
                                    </span>
                                 )}
                            />
                            <Column title="Funded Date" dataIndex="updatedAt" key="updatedAt" render={(_, record) => record.funded ? record.updatedAt : ""} />
                            {localStorage.getItem('superUser') === 'true' && (
                                <Column
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <Space size="middle">
                                    {(!record.funded) ? <a onClick={() => orderToFund(record.reportKey)}>Fund Order</a> : <span>Funded</span> }
                                    </Space>
                                )}
                                />
                            )}
                        </Table>
                    </Col>
                </Row>
        
        </LoadingOverlay>
    </>
    );
}

export default FundReport;
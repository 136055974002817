import React, { useState, useCallback, useEffect, Fragment, useRef } from "react";
import gql from "graphql-tag";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import { Alert, Container, Row, Col } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {AllCommunityModules} from "@ag-grid-community/all-modules";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import NoteIcon from './NoteIcon';
import ServerSideDatasource from './ServerSideDatasource'
import { Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons'


const Styles = styled.div`
    .ag-cell {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    label {
        font-weight: normal !important;
    }

    .div-percent-bar {
        display: inline-block;
        height: 20px;
        position: relative;
    }

    .div-percent-value {
        position: absolute;
        padding-left: 4px;
        font-weight: bold;
        font-size: 13px;
    }

    .div-outer-div {
        display: inline-block;
        height: 100%;
        width: 100%;
    }
`;

/*const query = gql`
  query getAllTypeTableData {
    getAllTypeTableData {
        paymentId
        paymentType
        amount
        cusLeaseNo
        guaranteeYr
        fullName
        email
        phone
        address {
        address1
        address2
        city
        state
        zip
        addressType
        }
        redemptionDate
        cardType
        ownHome
        orderId
        orderDate
        shipDate
        shipMethod
        token
        cusLeaseNo
        addrChangeReason
        autoRedeemed
        checkAction
        reissueDate
  }
  }
`;*/

/*const query = (request) => {
    
    return {
      query: gql`
        query GetAllTypeTableDataChunks(
            
            $start: Int, 
            $end: Int, 
            $sorting: [SortModel], 
            
            
          ) {
          getAllTypeTableDataChunks(
            input: {
                startRow: $start,    
                endRow: $end,
                sorting: $sorting
            }
                  
          ) {
            allTypeTableData {
                paymentId
                paymentType
                amount
                cusLeaseNo
                guaranteeYr
                fullName
                email
                phone
                address {
                    address1
                    address2
                    city
                    state
                    zip
                    addressType
                }
                redemptionDate
                cardType
                ownHome
                orderId
                orderDate
                shipDate
                shipMethod
                token
                cusLeaseNo
                addrChangeReason
                autoRedeemed
                checkAction
                reissueDate
            }
          }
        }
      `,
      variables: {
        input: {
            start: request.startRow,
            end: request.endRow,
            sorting: request.sortModel,
        }
        
      },
    }
  };*/

const query = gql`
  query getAllTypeTableDataChunks ($allTypeInput: allTypeInput){
    getAllTypeTableDataChunks(input: $allTypeInput) {
        allTypeTableData {
            paymentId
            paymentType
            amount
            cusLeaseNo
            guaranteeYr
            fullName
            email
            phone
            address {
                address1
                address2
                city
                state
                zip
                addressType
            }
            redemptionDate
            cardType
            ownHome
            orderId
            orderDate
            shipDate
            shipMethod
            token
            cusLeaseNo
            addrChangeReason
            autoRedeemed
            checkAction
            reissueDate
            doesNoteExist
        }
    }
  }
`;  

const CustomUPSRenderer = ({ value }) => {
    return (
      <>
        {value && (
          <Tooltip
            title={
              <div className="d-flex flex-column gap-2">
                {value.map((item, index) => (
                  <div key={index}>{item}{index !== value.length - 1}</div>
                ))}
              </div>
            }
            color="#E2620E"
          >
            <div>
              {value[0]}{' '}
              {value.length > 0 && <InfoCircleOutlined className="cursor-auto" />}
            </div>
          </Tooltip>
        )}
      </>
    );
};

const AllTypeTableNew = (props) => {
    //console.log(props);
    const gridRef = useRef();
    const quickFilterRef = useRef('');
    const quickOptionFilterRef = useRef('');
    let pageSize = 10;
    let versionCounter = 1;
    let icons = {
            columnRemoveFromGroup: '<i class="fa fa-times"/>',
            filter: '<i class="fa fa-filter"/>',
            sortAscending: '<i class="fa fa-long-arrow-alt-down"/>',
            sortDescending: '<i class="fa fa-long-arrow-alt-up"/>',
            groupExpanded: '<i class="far fa-minus-square"/>',
            groupContracted: '<i class="far fa-plus-square"/>'
        };
    const [api, setApi] = useState(null);
    const [rowCount, setRowCount] = useState(null);
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [quickFilterText, setquickFilterText] = useState(null);
    const [cacheBlockSize, setCacheBlockSize] = useState(100);
    const [maxBlocksInCache, setmMxBlocksInCache] = useState(10);
    const sortModel = [{colId: 'createdAt', sort: 'desc'}];
    const [filterText, setFilterText] = useState('');
    //let {loading, data, error} = useQuery(query, {variables: {allTypeInput: {startRow: 0, endRow: 1, sorting:sortModel}}});
    

    const onGridReady = useCallback((params) => {
        //console.log(params);
        const datasource = new ServerSideDatasource(props.client, cacheBlockSize, quickFilterRef, quickOptionFilterRef);
        params.api.setServerSideDatasource(datasource);
        setApi(params.api);
        calculateRowCount();
    }, []);

    const onCellClicked = (event) => {
        //console.log(event);
        //console.log('onCellClicked: ' + event.data.name + ', row ' + event.rowIndex+', mobile:'+event.data.mobile);
    };

    const onRowSelected = (event) => {
        //console.log('onRowSelected: ' + event.node.data.name);
    };

    const onQuickFilterText = useCallback((event) => {
        const searchFor = event.target.value || ""
        const searchOption = document.getElementById("searchOption").value;
        if(searchFor.length > 4){
            setFilterText(event.target.value);
            setquickFilterText(event.target.value);
        }
    }, []);

    const debounce = (fn, delay) => {
        let timer;
        return function () {
          clearTimeout(timer);
          timer = setTimeout(() => {
            fn();
          }, delay);
        };
      };

    const debouncedRefreshSSRM = debounce(
        () =>
          gridRef.current.api.refreshServerSideStore({
            purge: true,
          }),
        500
      );
    
      useEffect(() => {
        if (gridRef.current.api == null) {
          return;
        }
        debouncedRefreshSSRM();
      }, [filterText]);

    const filterResults = useCallback(() => {
        versionCounter++;
        gridRef.current.api.refreshServerSide({ route: filterText, purge: true });
    }, [versionCounter]);

    const calculateRowCount = () => {
        if (rowData) {
            if (api) {
                const model = api.getModel();
                const totalRows = rowData.length;
                const processedRows = model.getRowCount();
                setRowCount(processedRows.toLocaleString() + ' / ' + totalRows.toLocaleString());
            } else {
                setRowCount(0);
            }   
        }
    };
    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text='Loading your content...'
                >
                <NavigationBar />
                <Jumbotron />
                    <br />
                    <Container>
                        <Row>
                        <Col md={12}>
                            <Styles>
                                <div style={{ display: "inline-block", width: "100%", marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ float: "left" }}>
                                    </div>
                                    <div style={{ float: "center", marginLeft: 20 }}>
                                        <label htmlFor="quickFilter">Search:&nbsp;</label>
  <select style={{width:"20%"}} id="searchOption" ref={quickOptionFilterRef}>
  <option value="fullName">Full Name</option>
    {/* <option value="firstName">First Name</option>
    <option value="lastName">Last Name</option> */}
    <option value="amount">Amount</option>
    <option value="email">Email</option>
    <option value="phone">Phone</option>
    <option value="address1">Address 1</option>
    <option value="token">Tracking Number</option>
    <option value="cusLeaseNo">Account Number</option>
    <option value="guaranteeYr">Guarantee year</option>
    <option value="paymentType">Payment Type</option>
  </select>&nbsp;&nbsp;&nbsp;
                                        <input type="text" id="quickFilter" ref={quickFilterRef} onChange={onQuickFilterText} placeholder="Type text to filter..." />
                                    </div>

                                </div>
                                <div style={{ height: 400, width: 1100 }} className="ag-theme-balham">
                                    <AgGridReact
                                        frameworkComponents={{
                                            NoteIcon: NoteIcon,
                                            CustomUPSRenderer: CustomUPSRenderer
                                        }}
                                        ref={gridRef}
                                        // listening for events
                                        rowModelType={'serverSide'}
                                        onGridReady={onGridReady}
                                        onRowSelected={onRowSelected}
                                        onCellClicked={onCellClicked}
                                        onModelUpdated={calculateRowCount}
                                        pagination={true}
                                        paginationPageSize={pageSize}
                                        quickFilterText={quickFilterText}
                                        enableCellTextSelection={true}
                                        cacheBlockSize={cacheBlockSize}
                                        maxBlocksInCache={maxBlocksInCache}
                                        serverSideInfiniteScroll={true}
                                        serverSideStoreType={'partial'}
                                        // binding to an object property
                                        icons={icons}

                                        // register all modules (row model, csv/excel, row grouping etc)
                                        modules={AllCommunityModules}

                                        // no binding, just providing hard coded strings for the properties
                                        // boolean properties will default to true if provided (ie suppressRowClickSelection => suppressRowClickSelection="true")
                                        suppressRowClickSelection
                                        rowSelection="multiple"
                                        groupHeaders

                                        // setting grid wide date component
                                        //dateComponentFramework={DateComponent}

                                        // setting default column properties
                                        defaultColDef={{
                                            resizable: true,
                                            filter: true,
                                            //headerComponentFramework: SortableHeaderComponent,
                                            headerComponentParams: {
                                                menuIcon: 'fa-bars'
                                            }
                                        }}>
                                        
                                        <AgGridColumn headerName="Customer Information">
                                            <AgGridColumn headerName="Payment Type" field="paymentType" width={200} cellRenderer="NoteIcon" sortable="true" />
                                            <AgGridColumn headerName="Guarantee year" field="guaranteeYr" width={200} sortable="true" />
                                            <AgGridColumn headerName="Amount" field="amount" width={200} sortable="true" />
                                            <AgGridColumn headerName="Account Number" field="cusLeaseNo" width={150} sortable="true" />
                                            <AgGridColumn headerName="Tracking Number" field="token" width={150} sortable="true" />
                                            <AgGridColumn headerName="UPS Tracking" field="upsTracking" width={150} cellRenderer="CustomUPSRenderer" />
                                            <AgGridColumn headerName="Name" field="fullName" width={150} sortable="true" />
                                            <AgGridColumn headerName="Attention To" field="attentionTo" width={150} sortable="true" />
                                            <AgGridColumn headerName="Email" field="email" width={150} sortable="true" />
                                            <AgGridColumn headerName="Phone" field="phone" width={150} sortable="true" />
                                            <AgGridColumn headerName="Order Date" field="orderDate" width={100} sortable="true" />
                                            <AgGridColumn headerName="Email Sent Date" field="invoiceOrderFundingDate" width={100} sortable="true" />
                                            <AgGridColumn headerName="Redemption Date" field="redemptionDate" width={150} sortable="true" />
                                            <AgGridColumn headerName="Ship Date" field="shipDate" width={100}   sortable="true" />
                                            <AgGridColumn headerName="Ship Method" field="shipMethod" width={100}  />
                                            <AgGridColumn headerName="Card Type" field="cardType" width={100}  sortable="true" />
                                            <AgGridColumn headerName="Still Own Home" field="ownHome" width={100}  />
                                            <AgGridColumn headerName="Order Id" field="orderId" width={100}  />
                                            <AgGridColumn headerName="Auto Redeemed" field="autoRedeemed" width={70} />
                                            <AgGridColumn headerName="Check Action" field="checkAction" width={70}  />
                                            <AgGridColumn headerName="Reissued Payment Method" field="reissuePaymentMethod" width={70}  />
                                            <AgGridColumn headerName="Check Reissue Date" field="reissueDate" width={70}  />
                                            
                                        </AgGridColumn>
                                        <AgGridColumn headerName="Install Address">
                                            <AgGridColumn headerName="Install Address1" field="iaddress1" width={150} />
                                            <AgGridColumn headerName="Install Address2" field="iaddress2" width={50} />
                                            <AgGridColumn headerName="Install City" field="icity" width={100} />
                                            <AgGridColumn headerName="Install State" field="istate" width={100} />
                                            <AgGridColumn headerName="Install Zip" field="izip" width={50} />
                                        </AgGridColumn>
                                        <AgGridColumn headerName="Mailing Address">
                                            <AgGridColumn headerName="Mailing Address1" field="maddress1" width={150} />
                                            <AgGridColumn headerName="Mailing Address2" field="maddress2" width={50} />
                                            <AgGridColumn headerName="Mailing City" field="mcity" width={100} />
                                            <AgGridColumn headerName="Mailing State" field="mstate" width={100} />
                                            <AgGridColumn headerName="Mailing Zip" field="mzip" width={50} />
                                            <AgGridColumn headerName="Address Change Reason" field="addrChangeReason" width={150} />
                                        </AgGridColumn>
                                    </AgGridReact>
                                </div>
                            </Styles>
                        </Col>
                        </Row>
                        
                    </Container>
                    
                    <br />
                </LoadingOverlay>
        </Fragment>
      );
}

export default AllTypeTableNew;
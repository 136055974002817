import React from "react";
import NoteIcon from "../../NoteIcon";
import { compareValues } from "./functions";
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from "antd";

export const peguTableColumns = [
  {
    title: "Customer Information",
    align: "left",
    children: [
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        sorter: (a, b) => compareValues(a.fullName, b.fullName),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => compareValues(a.email, b.email),
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: (a, b) => compareValues(a.phone, b.phone),
      },
      {
        title: "Redemption Date",
        dataIndex: "redemptionDate",
        key: "redemptionDate",
        sorter: (a, b) => compareValues(a.redemptionDate, b.redemptionDate),
      },
      {
        title: "Card Type",
        dataIndex: "cardType",
        key: "cardType",
        sorter: (a, b) => compareValues(a.cardType, b.cardType),
      },
      {
        title: "UPS Tracking",
        width: 180,
        render: (_, record) =>{
          return (
              <>
              {record.upsTracking && (
                      <>      
                  <Tooltip title={
                    <div className="d-flex flex-column gap-2">
                      {record.upsTracking.map((item, index) => (
                        <div key={index}>{item}{index !== record.upsTracking.length - 1}</div>
                      ))}
                    </div>
                  } color='#E2620E'>
                              <div>
                              {record.upsTracking[0]} {record.upsTracking.length > 0 && (
                                <InfoCircleOutlined className="cursor-auto"/>
                              )}
                              </div>    
                          </Tooltip>                                
                      </>
              )}
              </>
          )
      }
      },
      {
        title: "Guarantee Year",
        dataIndex: "guaranteeYr",
        key: "guaranteeYr",
        sorter: (a, b) => compareValues(a.guaranteeYr, b.guaranteeYr),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        sorter: (a, b) => compareValues(a.amount, b.amount),
      },
      {
        title: "Customer Number",
        dataIndex: "cusLeaseNo",
        key: "cusLeaseNo",
        sorter: (a, b) => compareValues(a.cusLeaseNo, b.cusLeaseNo),
      },
      {
        title: "Order Id",
        dataIndex: "orderId",
        key: "orderId",
        sorter: (a, b) => compareValues(a.orderId, b.orderId),
      },
      {
        title: "Order Date",
        dataIndex: "orderDate",
        key: "orderDate",
        sorter: (a, b) => compareValues(a.orderDate, b.orderDate),
      },
      {
        title: "Email Sent Date",
        dataIndex: "invoiceOrderFundingDate",
        key: "invoiceOrderFundingDate",
        sorter: (a, b) => compareValues(a.invoiceOrderFundingDate, b.invoiceOrderFundingDate),
      },
      {
        title: "Ship Method",
        dataIndex: "shipMethod",
        key: "shipMethod",
      },
    ],
  },
  {
    title: "Install Address",
    align: "left",
    children: [
      {
        title: "Install Address1",
        dataIndex: "iaddress1",
        key: "iaddress1",
        sorter: (a, b) => compareValues(a.iaddress1, b.iaddress1),
      },
      {
        title: "Install Address2",
        dataIndex: "iaddress2",
        key: "iaddress2",
        sorter: (a, b) => compareValues(a.iaddress2, b.iaddress2),
      },
      {
        title: "Install City",
        dataIndex: "icity",
        key: "icity",
        sorter: (a, b) => compareValues(a.icity, b.icity),
      },
      {
        title: "Install State",
        dataIndex: "istate",
        key: "istate",
        sorter: (a, b) => compareValues(a.istate, b.istate),
      },
      {
        title: "Install Zip",
        dataIndex: "izip",
        key: "izip",
        sorter: (a, b) => compareValues(a.izip, b.izip),
      },
    ],
  },
  {
    title: "Mailing Address",
    align: "left",
    children: [
      {
        title: "Mailing Address1",
        dataIndex: "maddress1",
        key: "maddress1",
        sorter: (a, b) => compareValues(a.maddress1, b.maddress1),
      },
      {
        title: "Mailing Address2",
        dataIndex: "maddress2",
        key: "maddress2",
        sorter: (a, b) => compareValues(a.maddress2, b.maddress2),
      },
      {
        title: "Mailing City",
        dataIndex: "mcity",
        key: "mcity",
        sorter: (a, b) => compareValues(a.mcity, b.mcity),
      },
      {
        title: "Mailing State",
        dataIndex: "mstate",
        key: "mstate",
        sorter: (a, b) => compareValues(a.mstate, b.mstate),
      },
      {
        title: "Mailing Zip",
        dataIndex: "mzip",
        key: "mzip",
        sorter: (a, b) => compareValues(a.mzip, b.mzip),
      },
    ],
  },
];

export const cusTableColumns = [
  {
    title: "Customer Information",
    align: "left",
    children: [
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        sorter: (a, b) => compareValues(a.fullName, b.fullName),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => compareValues(a.email, b.email),
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: (a, b) => compareValues(a.phone, b.phone),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        sorter: (a, b) => compareValues(a.amount, b.amount),
      },
      {
        title: "Redemption Date",
        dataIndex: "redemptionDate",
        key: "redemptionDate",
        sorter: (a, b) => compareValues(a.redemptionDate, b.redemptionDate),
      },
      {
        title: "Card Type",
        dataIndex: "cardType",
        key: "cardType",
        sorter: (a, b) => compareValues(a.cardType, b.cardType),
      },
      {
        title: "UPS Tracking",
        width: 180,
        render: (_, record) =>{
          return (
              <>
              {record.upsTracking && (
                      <>      
                  <Tooltip title={
                    <div className="d-flex flex-column gap-2">
                      {record.upsTracking.map((item, index) => (
                        <div key={index}>{item}{index !== record.upsTracking.length - 1}</div>
                      ))}
                    </div>
                  } color='#E2620E'>
                              <div>
                              {record.upsTracking[0]} {record.upsTracking.length > 0 && (
                                <InfoCircleOutlined className="cursor-auto"/>
                              )}
                              </div>    
                          </Tooltip>                                
                      </>
              )}
              </>
          )
      }
      },
      {
        title: "Still Own Home",
        dataIndex: "ownHome",
        key: "ownHome",
        sorter: (a, b) => compareValues(a.ownHome, b.ownHome),
      },
      {
        title: "Order Id",
        dataIndex: "orderId",
        key: "orderId",
        sorter: (a, b) => compareValues(a.orderId, b.orderId),
      },
      {
        title: "Order Date",
        dataIndex: "orderDate",
        key: "orderDate",
        sorter: (a, b) => compareValues(a.orderDate, b.orderDate),
      },
      {
        title: "Email Sent Date",
        dataIndex: "invoiceOrderFundingDate",
        key: "invoiceOrderFundingDate",
        sorter: (a, b) => compareValues(a.invoiceOrderFundingDate, b.invoiceOrderFundingDate),
      },
      {
        title: "Ship Method",
        dataIndex: "shipMethod",
        key: "shipMethod",
      },
    ],
  },
  {
    title: "Install Address",
    align: "left",
    children: [
      {
        title: "Install Address1",
        dataIndex: "iaddress1",
        key: "iaddress1",
        sorter: (a, b) => compareValues(a.iaddress1, b.iaddress1),
      },
      {
        title: "Install Address2",
        dataIndex: "iaddress2",
        key: "iaddress2",
        sorter: (a, b) => compareValues(a.iaddress2, b.iaddress2),
      },
      {
        title: "Install City",
        dataIndex: "icity",
        key: "icity",
        sorter: (a, b) => compareValues(a.icity, b.icity),
      },
      {
        title: "Install State",
        dataIndex: "istate",
        key: "istate",
        sorter: (a, b) => compareValues(a.istate, b.istate),
      },
      {
        title: "Install Zip",
        dataIndex: "izip",
        key: "izip",
        sorter: (a, b) => compareValues(a.izip, b.izip),
      },
    ],
  },
  {
    title: "Mailing Address",
    align: "left",
    children: [
      {
        title: "Mailing Address1",
        dataIndex: "maddress1",
        key: "maddress1",
        sorter: (a, b) => compareValues(a.maddress1, b.maddress1),
      },
      {
        title: "Mailing Address2",
        dataIndex: "maddress2",
        key: "maddress2",
        sorter: (a, b) => compareValues(a.maddress2, b.maddress2),
      },
      {
        title: "Mailing City",
        dataIndex: "mcity",
        key: "mcity",
        sorter: (a, b) => compareValues(a.mcity, b.mcity),
      },
      {
        title: "Mailing State",
        dataIndex: "mstate",
        key: "mstate",
        sorter: (a, b) => compareValues(a.mstate, b.mstate),
      },
      {
        title: "Mailing Zip",
        dataIndex: "mzip",
        key: "mzip",
        sorter: (a, b) => compareValues(a.mzip, b.mzip),
      },
    ],
  },
];

export const allTypeTableColumns = [
  {
    title: "Customer Information",
    align: "left",
    children: [
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        sorter: true,
        render: (pType, record) => {
          return (
            <>
              {pType} <NoteIcon value={record} />
            </>
          );
        },
      },
      {
        title: "Guarantee Year",
        dataIndex: "guaranteeYr",
        sorter: true,
      },
      { title: "Amount", dataIndex: "amount", sorter: true },
      {
        title: "Account Number",
        dataIndex: "cusLeaseNo",
        sorter: true,
      },
      {
        title: "Tracking Number",
        dataIndex: "token",
        sorter: true,
      },
      { title: "Name", dataIndex: "fullName", sorter: true },
      { title: "Email", dataIndex: "email", sorter: true },
      { title: "Phone", dataIndex: "phone", sorter: true },
      { title: "Order Date", dataIndex: "orderDate", sorter: true },
      { title: "Email Sent Date", dataIndex: "invoiceOrderFundingDate", sorter: true },
      {
        title: "Redemption Date",
        dataIndex: "redemptionDate",
        sorter: true,
      },
      { title: "Ship Date", dataIndex: "shipDate", sorter: true },
      { title: "Ship Method", dataIndex: "shipMethod", sorter: true },
      { title: "Card Type", dataIndex: "cardType", sorter: true },
      { title: "Still Own Home", dataIndex: "ownHome", sorter: true },
      { title: "Order Id", dataIndex: "orderId", sorter: true },
      { title: "Auto Redeemed", dataIndex: "autoRedeemed", sorter: true },
      { title: "Check Action", dataIndex: "checkAction", sorter: true },
      { title: "Check Reissue Date", dataIndex: "reissueDate", sorter: true },
    ],
  },
  {
    title: "Install Address",
    align: "left",
    children: [
      { title: "Install Address1", dataIndex: "iaddress1", sorter: true },
      { title: "Install Address2", dataIndex: "iaddress2", sorter: true },
      { title: "Install City", dataIndex: "icity", sorter: true },
      { title: "Install State", dataIndex: "istate", sorter: true },
      { title: "Install Zip", dataIndex: "izip", sorter: true },
    ],
  },
  {
    title: "Mailing Address",
    align: "left",
    children: [
      { title: "Mailing Address1", dataIndex: "maddress1", sorter: true },
      { title: "Mailing Address2", dataIndex: "maddress2", sorter: true },
      { title: "Mailing City", dataIndex: "mcity", sorter: true },
      { title: "Mailing State", dataIndex: "mstate", sorter: true },
      { title: "Mailing Zip", dataIndex: "mzip", sorter: true },
      {
        title: "Address Change Reason",
        dataIndex: "addrChangeReason",
        sorter: true,
      },
    ],
  },
];

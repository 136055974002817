import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { Table, Tag, Space } from 'antd';
import gql from "graphql-tag";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import 'antd/dist/antd.css';
import {
    Form, Input,Button, Row, Col
  } from 'antd';
import { Alert } from 'reactstrap';
import EditForm from './EditForm';
import { formatDate, convertTimestampToMMDDYYYY } from '../../_helpers/utils';

const { Column, ColumnGroup } = Table;
const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 32,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  const query = gql`
  query searchPayments($searchString: String, $editSearch: Boolean){
    searchPayments(searchString: $searchString, editSearch: $editSearch) {
        paymentType
        amount
        cusLeaseNo
        guaranteeYr
        fullName
        email
        phone
        paymentId
        status
        formString
        invoiceOrderFundingDate
    }
  }
`;

const mutation = gql`
    mutation ResendEmail($paymentId: String, $email: String, $mailingAddress1: String, $mailingAddress2: String, $mailingCity: String, $mailingState: String, $mailingZip: String){
        resendEmail(input: {paymentId: $paymentId, email: $email, mailingAddress1: $mailingAddress1, mailingAddress2: $mailingAddress2, 
            mailingCity: $mailingCity, mailingState: $mailingState, mailingZip: $mailingZip}) 
    }
`;
const ResendEmail = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const [spinnerDisplay2, setSpinnerDisplay2] = useState(false);
    const [loaderText, setLoaderText] = useState('Searching your data...');
    const [searchQuery, { loading, data }] = useLazyQuery(query);
    const [tabledata, setTableData] = useState([]);
    const [showSuccessMessage, setshowSuccessMessage] = useState(false);
    const [showErrorMessage, setshowErrorMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalFieldValues, setModalFieldValues] = useState({});
    const [modalPaymentId, setModalPaymentId] = useState(0);
    const [searchString, setSearchString] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const client = useApolloClient();
    const onFinish = values => {
        console.log('Success:', values);
        setSpinnerDisplay(true);
        setshowSuccessMessage(false);
        setshowErrorMessage(false);
        setSearchString(values.searchString);
        searchQuery({
            variables: {
                searchString: values.searchString,
                editSearch: false
            },
            fetchPolicy: "network-only"
            });
    };
    useEffect(() => {
        if (data && !loading && spinnerDisplay) {
            if (localStorage.getItem('bpsadminFlag') === 'true' && 
            (localStorage.getItem('email').toLowerCase() === 'ginag@bestpayment.solutions' || 
            localStorage.getItem('email').toLowerCase() === 'sushantv@bestpayment.solutions' || 
            localStorage.getItem('email').toLowerCase() === 'annag@bestpayment.solutions')) {
                setEditEmail(false);
            } else { setEditEmail(true); }
            console.log(data);
            const tableData = [];
            data.searchPayments.map(entry => {
                if (entry.status === 'success') {
                    const searchEntry = {
                        fullName: entry.fullName,
                        paymentType: entry.paymentType,
                        phone: entry.phone,
                        amount: entry.amount,
                        email: entry.email,
                        key: entry.paymentId,
                        paymentId: entry.paymentId,
                        invoiceOrderFundingDate: entry.invoiceOrderFundingDate ? convertTimestampToMMDDYYYY(entry.invoiceOrderFundingDate): "",
                        formString: JSON.parse(entry.formString)
                    };
                    console.log()
                    tableData.push(searchEntry);
                }
                
            })
            setTableData(tableData);
            setSpinnerDisplay(false);
            setSpinnerDisplay2(false);
        }
    }, [spinnerDisplay, data, loading]);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    const [resendEmail] = useMutation(mutation);
    const sendEmail = (paymentId) => {
        
        resendEmail({
            variables: { paymentId }
            }).then(
            res => {
                console.log('res: ',res);
                if (res) {
                    setshowSuccessMessage(true);
                }
            },
            err => {
                console.log(err); 
                setshowErrorMessage(true);
            }
            );
    }

    const editResend = (values) => {
        setModalVisible(false);
        //console.log(values);
        setSpinnerDisplay2(true);
        setLoaderText('Updating and resending email...');
        resendEmail({
            variables: { 
                paymentId: values.paymentId,
                email:  values.email,
                mailingAddress1: values.mailingAddress1,
                mailingAddress2: values.mailingAddress2,
                mailingCity: values.mailingCity,
                mailingState: values.mailingState,
                mailingZip: values.mailingZip
            }
            }).then(
            res => {
                //console.log('res: ',res);
                if (res) {
                    setshowSuccessMessage(true);
                    
                    setshowErrorMessage(false);
                    setLoaderText('Searching again after update...');
                    client.query({
                        query,
                        variables: {
                            searchString,
                            editSearch: false
                        },
                        fetchPolicy: "network-only"
                      }).then(data => {
                          //console.log('debug:',data);
                          const tableData = [];
                          data.data.searchPayments.map(entry => {
                            const searchEntry = {
                                fullName: entry.fullName,
                                paymentType: entry.paymentType,
                                phone: entry.phone,
                                amount: entry.amount,
                                email: entry.email,
                                key: entry.paymentId,
                                paymentId: entry.paymentId,
                                formString: JSON.parse(entry.formString),
                                invoiceOrderFundingDate: entry.invoiceOrderFundingDate ? convertTimestampToMMDDYYYY(entry.invoiceOrderFundingDate): "",
                            };
                            //console.log()
                            tableData.push(searchEntry);
                        })
                        setTableData(tableData);
                        setSpinnerDisplay(false);
                        setSpinnerDisplay2(false);
                      });
                    /*searchQuery({
                        variables: {
                            searchString
                        },
                        fetchPolicy: "network-only"
                        });*/
                }
            },
            err => {
                console.log(err); 
                setshowErrorMessage(true);
            }
            );
    }

    const onCancel = () => {
        setShowModal(false);
        setModalVisible(false);
    }

    const prepareModal = (record) => {
        setModalVisible(true);
        const formValues = {formString: record.formString, fullName: '', 
            cardType: '', phone: '', amount: '', paymentType: record.paymentType};
        //setModalFieldValues(record.formString);
        setModalFieldValues(formValues);
        setModalPaymentId(record.paymentId);
    }

    return (
        <>
        <LoadingOverlay
            active={spinnerDisplay || spinnerDisplay2}
            spinner
            text={loaderText}
            >
        <NavigationBar />
        <Jumbotron />
            <br /> 
                    <Row>
                        <Col span={12} offset={5}>
                        <Alert color="success" style={{ display: ((showSuccessMessage) ? 'block' : 'none') }}>
                              The email has been successfully sent.  
                        </Alert>
                        <Alert color="danger" style={{ display: ((showErrorMessage) ? 'block' : 'none') }}>
                            There was an error sending the email.
                        </Alert>
                        </Col>
                    </Row>
                    <Form
                    {...layout}
                    name="Search"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    >
                    <Row>
                    <Col span={12} offset={5}>
                        <Form.Item
                            name="searchString"
                            rules={[
                            {
                                required: true,
                                message: 'Please enter Search Text',
                            },
                            ]}
                        >
                            <Input placeholder="Search by name, email or phone"/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                            Search
                            </Button>
                        </Form.Item>
                    </Col>
                    </Row>
                    </Form>
               
                <Row>
                    <Col span={16} offset={4}> 
                        <Table dataSource={tabledata}>
                            <Column title="Name" dataIndex="fullName" key="fullName" />
                            <Column title="Email" dataIndex="email" key="email" />
                            <Column title="Phone" dataIndex="phone" key="phone" />
                            <Column title="Amount" dataIndex="amount" key="Amount" />
                            <Column title="Payment Type" dataIndex="paymentType" key="paymentType" />
                            <Column title="Email Sent Date" dataIndex="invoiceOrderFundingDate" key="invoiceOrderFundingDate" />
                            
                            <Column
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                <a onClick={() => prepareModal(record)}>Resend Email</a>
                                {
                                    modalVisible ? 
                                    <EditForm
                                    visible={true}
                                    onSaveResend={editResend}
                                    onCancel={onCancel}
                                    fieldValues={modalFieldValues}
                                    paymentId={modalPaymentId}
                                    displayAll={false}
                                    title="Edit and Resend"
                                    displayCSR={true}
                                    displayEmail={true}
                                    editEmail={editEmail}
                                    /> : null
                                }
                                
                                </Space>
                            )}
                            />
                        </Table>
                    </Col>
                </Row>
        
        </LoadingOverlay>
    </>
    );
}

export default ResendEmail;
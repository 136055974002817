export function formatAmount(amount) {
    if (isNaN(amount)) {
        return amount;
    }

    const formattedAmount = Number(amount).toFixed(2);

    const numberWithCommas = formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return `$${numberWithCommas}`;
}

export function convertTimestampToMMDDYYYY(timestamp) {
    const date = new Date(+timestamp);
    // Get month, day, and year
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

export function formatDate(inputDate) {
    const date = new Date(inputDate);

    // Get month, day, and year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    // Format as MM-DD-YYYY
    const formattedDate = `${month}-${day}-${year}`;

    return formattedDate;
}

export const RoleName = {
    ROOT_ADMIN: 'Root Admin', //superUser: true, all true
    MANAGEMENT_LEVEL: 'BPS Management', // managementFlag: true, all: false
    // BPS ADMINS
    SUPPORT_LEVEL: 'BPS Support', // bpsadminFlag: true, rest all false
    ACCOUNTING_LEVEL: 'Accounting', // accountingFlag: true, all: false
    CLIENT_ADMIN: 'Client Admin' // all false
}

export const roleOptions = [
    {
        value: RoleName.ROOT_ADMIN,
        label: 'Root Admin',
    },
    {
        value: RoleName.MANAGEMENT_LEVEL,
        label: 'BPS Management',
    },
    {
        value: RoleName.SUPPORT_LEVEL,
        label: 'BPS Support',
    },
    {
        value: RoleName.ACCOUNTING_LEVEL,
        label: 'Accounting',
    },
    {
        value: RoleName.CLIENT_ADMIN,
        label: 'Client Admin',
    },
]

export const statusOptions = [
    {
        value: 'active',
        label: 'Active',
    },
    {
        value: 'inactive',
        label: 'Inactive',
    },
]

export function removeGraphQLErrorPrefix(errorMessage) {
    const prefix = "GraphQL error: ";
    if (errorMessage.startsWith(prefix)) {
        return errorMessage.substring(prefix.length);
    }
    return errorMessage;
}

export function getRoleFlagObject(roleName) {
    switch (roleName) {
        case RoleName.ROOT_ADMIN:
            return { superUser: true, bpsadminFlag: true, accountingFlag: true, managementFlag: true }
        case RoleName.ACCOUNTING_LEVEL:
            return { superUser: false, bpsadminFlag: false, accountingFlag: true, managementFlag: false }
        case RoleName.MANAGEMENT_LEVEL:
            return { superUser: false, bpsadminFlag: false, accountingFlag: false, managementFlag: true }
        case RoleName.SUPPORT_LEVEL:
            return { superUser: false, bpsadminFlag: true, accountingFlag: false, managementFlag: false }
        default:
            return { superUser: false, bpsadminFlag: false, accountingFlag: false, managementFlag: false }
    }
}
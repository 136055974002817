import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useSubscription } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavigationBar from './NavigationBar';
import Jumbotron from './Jumbotron';
import LoadingOverlay from 'react-loading-overlay';
import {
    Form,
    InputNumber,
    Checkbox, Col, Row, DatePicker, Button
  } from 'antd';
import 'antd/dist/antd.css';
//import Download from './Download';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CheckBoxGrid from './CheckBoxGrid';

const Styles = styled.div`
    .ant-checkbox-group  {
        width: 100%;
    }
    .ant-btn-primary {
        background-color: orange;
    }
`;
const query = gql`
  query getPaymentsProcessedData($startDate: String, $endDate: String){
    getPaymentsProcessedData(startDate: $startDate, endDate: $endDate) {
        cardType
        token
        fundDate
    }
  }
`;
const PaymentProcessedReport = (props) => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    
    const formItemLayout = {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 24,
        },
      };
    const colLabels = {
        'token': 'Tracking Number', 
        'cardType': 'Reward Type', 
        'fundDate': 'Date Processed'
    };
    
    const headerOrder = {
        'token': 'Tracking Number', 
        'cardType': 'Reward Type', 
        'fundDate': 'Date Processed'
    };
    
    const [reportDates, setReportDates] = useState({'sdate': '', "edate": ''});
    let [pullData, {loading, data }] = useLazyQuery(query, {
        fetchPolicy: 'network-only'
      });
    
    const fileName = "SunPower Payments - Payment-Processed-Report";
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [reportCols, setReportCols] = useState({});
    
    useEffect(() => {
        
        setTimeout(()=>{
            if (data && !loading && spinnerDisplay) {
            
                //console.log(data);
                let tempRowData = [];
                let checkTotalCount = 0;
                let virtualTotalCount = 0;
                let physicalTotalCount = 0;
                
                data.getPaymentsProcessedData.map(paymentEntry => {
                    if (paymentEntry['cardType'] === 'check'){
                        checkTotalCount++;
                    } else if (paymentEntry['cardType'] === 'visareward'){
                        physicalTotalCount++;
                    } else if (paymentEntry['cardType'] === 'virtualvisa'){
                        virtualTotalCount++;
                    }

                    let payment = {};
                    const keys = Object.keys(paymentEntry);
                    //console.log(reportCols);
                    keys.map(colKey => {
                        if (colKey !== 'address') {
                            if (reportCols[colKey]) {
                                payment[colLabels[colKey]] = paymentEntry[colKey];
                            }
                        }
                    })
                    tempRowData.push(payment);
                });
        
                const columnOrder = [];
                
                for (let key in headerOrder) {
                    //console.log(key,':',headerOrder[key]);
                    if (reportCols[key]) {
                        columnOrder.push(headerOrder[key]);
                    }
                }
                
                if (spinnerDisplay && tempRowData.length > 0) {
                    //console.log('tempRowData: ',tempRowData);
                    // Creating "data" sheet
                    const today = new Date();
                    const year = today.getFullYear();
                    const month = String(today.getMonth() + 1).padStart(2, '0');
                    const day = String(today.getDate()).padStart(2, '0');
                    const todaysDate = `${year}-${month}-${day}`;
                    const ws = XLSX.utils.json_to_sheet(tempRowData, { header: columnOrder });
                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

                    // Creating "summary" sheet
                    const summaryData = [
                        { 'Reward Type': "check", 'Total Payments Processed': checkTotalCount },
                        { 'Reward Type': "virtualvisa", 'Total Payments Processed': virtualTotalCount },
                        { 'Reward Type': "visareward", 'Total Payments Processed': physicalTotalCount }
                    ];
                    const summaryWs = XLSX.utils.json_to_sheet(summaryData);
                    XLSX.utils.book_append_sheet(wb, summaryWs, 'summary');

                    // Saving the Excel file
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    let data2 = new Blob([excelBuffer], { type: fileType });
                    if (reportDates['sdate'] === '') {
                        reportDates['sdate'] = todaysDate;
                    }
                    FileSaver.saveAs(data2, fileName + "[" + reportDates['sdate'] + "_" + reportDates['edate'] + "]" + fileExtension);
                    data2 = undefined;
                }
                tempRowData = [];
                setSpinnerDisplay(false);
            }
        }, 1000);
        
    }, [data, loading]);
    

    const onFinish = async (values) => {
        //console.log(columns);
        //console.log(reportDates);
        setSpinnerDisplay(true);
        
        let result = await pullData({
            variables: {
                startDate: reportDates['sdate'],
                endDate: reportDates['edate'],
            }
        });
        
        
    };

    const startDateCapture = date => {
        let tempDate = reportDates;
        tempDate['sdate'] = date.format('YYYY-MM-DD');
        setReportDates(tempDate);
    }

    const endDateCapture = date => {
        let tempDate = reportDates;
        tempDate['edate'] = date.format('YYYY-MM-DD');
        setReportDates(tempDate);
    }

    
    const colNames = {'token':'','cardType':'','fundDate': ''};

    const onCheckBoxChange = (colSelected) => {
        console.log(colSelected);
        setReportCols(colSelected);
    }

    const checkBoxList = [
        [
            {'id': 'token', 'label':'Tracking Number'},
            {'id': 'cardType', 'label':'Reward Type'},
            {'id': 'fundDate', 'label':'Date Processed'},
        ],
    ];

    

    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text='Loading your report data...'
                >
            <NavigationBar />
            <Jumbotron />
                <br />
            <Styles>
                
                
            <main>
                <div className="container">
	                <h2>Payments Processed Report</h2>	          
                    <p>Reports will be produced and downloaded as Excel</p>
                    <Form
                        name="report"
                        {...formItemLayout}
                        onFinish={onFinish}
                        >
                    <div className="card">
                        <div className="card-body">
                            
                            <CheckBoxGrid
                                checkBoxList={checkBoxList}
                                onCheckBoxChange = {onCheckBoxChange}
                                colNames = {colNames}
                            />
                            
                            <div className="form-row bg-light p-2 mt-4">
                                <div className="col-md">
                                    <div className="form-group">
                                    <Form.Item label="Start Date" value="sdate">
                                        <DatePicker onChange={startDateCapture} />
                                    </Form.Item>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-group">
                                    <Form.Item label="End Date" value="edate">
                                        <DatePicker onChange={endDateCapture} />
                                    </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <br />
                            <Row>
                                <Col span={6} offset={10}>
                                <Button type="primary" htmlType="submit" shape="round" size="large">
                                    Export Report
                                </Button>
                                </Col> 
                            </Row>

                            
                        </div>
                    </div>
                    </Form>
                </div>
                <br />
                
            </main>
            </Styles>
            </LoadingOverlay>
            
        </Fragment>
    );
}

export default PaymentProcessedReport;
import { BehaviorSubject } from "rxjs";
import { useMutation } from "@apollo/react-hooks";
import { handleResponse } from "../_helpers";
import gql from "graphql-tag";
import axios from "axios";
import { RoleName } from "../_helpers/utils";

const currentUserSubject = new BehaviorSubject(
  localStorage.getItem("currentUser")
);

export const authenticationService = {
  login,
  logout,
  loginSSO,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

const axiosGraphQL = axios.create({
  baseURL: process.env.REACT_APP_NODE_URL,
});

const LOGIN = gql`
  mutation Login($email: String, $password: String) {
    login(email: $email, password: $password) {
      id
      email
    }
  }
`;

const loginQuery = (email, password) => `
  mutation {
    login(input: {email: "${email}", password: "${password}"}) {
      token
      fname
      lname
      email
      title
      phone
      bpsadminFlag
      resetPwdFlag
      accountingFlag
      superUser
      managementFlag
    }
  }
`;
const SSOlogin = (token) => ` 
mutation{
  getUserDetailsViaToken(token: "${token}") {
      token
      fname
      lname
      email
      title
      phone
      bpsadminFlag
      resetPwdFlag
    }
}`;

function loginSSO(token) {
  return new Promise((resolve, reject) => {
    try {
      axiosGraphQL
        .post("", {
          query: SSOlogin(token),
        })
        .then((result) => {
          console.log(result);
          console.log(result.data);
          if (result.data.errors) {
            reject(result.data.errors[0].message);
            return;
          } else {
            console.log(result.data.data.getUserDetailsViaToken.token);
            //localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem(
              "currentUser",
              result.data.data.getUserDetailsViaToken.token
            );
            localStorage.setItem(
              "fname",
              result.data.data.getUserDetailsViaToken.fname
            );
            localStorage.setItem(
              "lname",
              result.data.data.getUserDetailsViaToken.lname
            );
            localStorage.setItem(
              "email",
              result.data.data.getUserDetailsViaToken.email
            );
            localStorage.setItem(
              "title",
              result.data.data.getUserDetailsViaToken.title
            );
            localStorage.setItem(
              "phone",
              result.data.data.getUserDetailsViaToken.phone
            );
            localStorage.setItem(
              "bpsadminFlag",
              result.data.data.getUserDetailsViaToken.bpsadminFlag
            );
            localStorage.setItem(
              "resetPwdFlag",
              result.data.data.getUserDetailsViaToken.resetPwdFlag
            );
            currentUserSubject.next(
              result.data.data.getUserDetailsViaToken.token
            );
            resolve(result.data.data.getUserDetailsViaToken.token);
          }

          //return user;
        });
    } catch (err) {
      console.log(err);
      console.log("failed");
      reject(err);
    }
  });
}

function login(email, password) {
  /*const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };*/
  //console.log(email,' : ',password);
  return new Promise((resolve, reject) => {
    try {
      axiosGraphQL
        .post("", {
          query: loginQuery(email, password),
        })
        .then((result) => {
          //console.log(result);
          //console.log(result.data);
          if (result.data.errors) {
            reject(result.data.errors[0].message);
            return;
          } else {
            console.log(result.data.data.login.token);
            const entry = result.data.data.login;
            //localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem("currentUser", result.data.data.login.token);
            localStorage.setItem("fname", result.data.data.login.fname);
            localStorage.setItem("lname", result.data.data.login.lname);
            localStorage.setItem("email", result.data.data.login.email);
            localStorage.setItem("title", result.data.data.login.title);
            localStorage.setItem("phone", result.data.data.login.phone);
            localStorage.setItem(
              "bpsadminFlag",
              result.data.data.login.bpsadminFlag
            );
            localStorage.setItem(
              "accountingFlag",
              result.data.data.login.accountingFlag
            );
            localStorage.setItem(
              "superUser",
              result.data.data.login.superUser
            );
            localStorage.setItem(
              "managementFlag",
              result.data.data.login.managementFlag
            );
            localStorage.setItem(
              "resetPwdFlag",
              result.data.data.login.resetPwdFlag
            );
            localStorage.setItem(
              "roleName",
              entry.superUser ? RoleName.ROOT_ADMIN : entry.bpsadminFlag ? RoleName.SUPPORT_LEVEL : entry.accountingFlag ? RoleName.ACCOUNTING_LEVEL : entry.managementFlag ? RoleName.MANAGEMENT_LEVEL : RoleName.CLIENT_ADMIN
            );
            currentUserSubject.next(result.data.data.login.token);
            resolve(result.data.data.login.token);
          }

          //return user;
        });
    } catch (err) {
      console.log(err);
      console.log("failed");
      reject(err);
    }
    /*return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
            .then(handleResponse)
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                currentUserSubject.next(user);
    
                return user;
            });*/
  });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}

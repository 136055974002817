import gql from 'graphql-tag';

const backlogUploaderSubscription = gql`
    subscription backlogFileUpdateStatus {
        backlogFileUpdateStatus {
            type
            message
        }
    }
`;

export default backlogUploaderSubscription;